export class PayIrasSourceType {
  static get draft() {
    return 'd'
  }
  static get original() {
    return 'o'
  }
  static get amendment() {
    return 'a'
  }
  static get all() {
    return '*'
  }
}

export class PayMethod {
  static get bank() {
    return 'bank'
  }
  static get cheque() {
    return 'cheque'
  }
}

export class PayRateType {
  static get monthly() {
    return 'm'
  }
  static get daily() {
    return 'd'
  }
  static get hourly() {
    return 'h'
  }
}

export class PayRunStatus {
  static get verification() {
    return 'v'
  }
  static get payment() {
    return 'p'
  }
  static get completed() {
    return 'c'
  }
}

export class PayRunType {
  static get wholeMonth() {
    return 'w'
  }
  static get adhoc() {
    return 'a'
  }
  static get firstHalf() {
    return 'h1'
  }
  static get secondHalf() {
    return 'h2'
  }
}

export class PayTranSource {
  static get basicSalary() {
    return 'bs'
  }
  static get recurring() {
    return 're'
  }
  static get overtime() {
    return 'ot'
  }
  static get leave() {
    return 'lv'
  }
  static get entry() {
    return 'en'
  }
  static get import() {
    return 'im'
  }
  static get fund() {
    return 'fd'
  }
  static get pyCpf() {
    return 'cpf'
  }
  static get yeCpf() {
    return 'ye_cpf'
  }
}

export enum PayTranSourceTable {
  ClaimRecord = 'claim_record',
  LeaveEntitlement = 'leave_entitlement',
  LeaveRecordDtl = 'leave_record_dtl'
}

export class PayTranGroup {
  static get basicPay() {
    return 'bp'
  }
  static get recurring() {
    return 're'
  }
  static get overtime() {
    return 'ot'
  }
  static get leave() {
    return 'lv'
  }
  static get adhoc() {
    return 'ad'
  }
  static get fund() {
    return 'fd'
  }
  static get cpfEmployee() {
    return 'cd'
  }
  static get netPay() {
    return 'np'
  }
  static get cpfEmployer() {
    return 'cr'
  }
  static get sdl() {
    return 'sd'
  }
  static get fwl() {
    return 'fw'
  }
}

export class PayItemCategory {
  static get basicPay() {
    return 'bp'
  }
  static get overtime() {
    return 'ot'
  }
  static get leave() {
    return 'lv'
  }
  static get fund() {
    return 'fd'
  }
  static get cpf() {
    return 'cp'
  }
  static get fwl() {
    return 'fw'
  }
  static get sdl() {
    return 'sd'
  }
  static get others() {
    return 'xx'
  }
}

export enum PayItemId {
  BasicPay = '557abfef-45d1-4d84-8c39-ee6c0b38d990'
}

export class ProrationType {
  static get noProration() {
    return 'x'
  }
  static get workDays() {
    return 'w'
  }
  static get calendarDays() {
    return 'c'
  }
}

export class WageType {
  static get ordinary() {
    return 'o'
  }
  static get additional() {
    return 'a'
  }
  static get nonCpf() {
    return 'x'
  }
}

export class PayItemType {
  static get addition() {
    return 'a'
  }
  static get deduction() {
    return 'd'
  }
  static get provision() {
    return 'p'
  }
  static get notApplicable() {
    return 'x'
  }
}

export class PayslipItemConfigQtyExpr {
  static get qtyOnly() {
    return 'q'
  }
  static get qtyRate() {
    return 'qr'
  }
  static get none() {
    return 'x'
  }
}

export class ReconcileField {
  static get payItem() {
    return 'pi'
  }
}

export class ReconcileWith {
  static get prevMonth() {
    return 'prev_mth'
  }
  static get currMonth() {
    return 'curr_mth'
  }
}

export class ReconcileType {
  static get field() {
    return 'field'
  }
  static get netSalaries() {
    return 'net_sal'
  }
}

// to check the id of sys_bank_file
export enum SysBankFile {
  DbsIdealInterbank = '15450f84-14bd-471e-ae4a-a6165d69c31e',
  DbsUffFast = '0eab89f5-2cdd-4394-a0ba-a72e6a4fab29',
  DbsInterbankGiro = '52beb5f1-dfbe-4f81-9dee-ef7996b38c2a',
  MaybankAps = '06ca8a68-3092-4d4d-afe8-0d972323a41a',
  OcbcVelocityGiroFast = 'a52c525b-b0b9-448c-b92b-6c2edba7c921',
  UobFastGiro = 'a0bf7df7-310a-4b1c-89bd-e6c3d26497fe'
}

export enum BankUobServiceType {
  GiroNormal = 'giro_normal',
  GiroExpress = 'giro_express',
  Fast = 'fast'
}

export class Ir8aCategory {
  static get ir8aSal() {
    return 'ir8a_sal'
  }
}
