import { CSSProperties, FC } from 'react'
import memoize from 'lodash/memoize'
import moment from 'moment-timezone'
import { Form, Tooltip } from '~/core-components'
import { Col, Row } from '~/components'
import { TimeLogStatus } from '~/constants'
import { TimeLogApprovalHistoryState } from '../../types'

interface TimeLogApprovalHistoriesProps {
  histories: TimeLogApprovalHistoryState[]
  hidden?: boolean
  submitterName?: string
  submittedDate?: string
}

const divStyles: CSSProperties = { marginTop: 8 }

export const TimeLogApprovalHistories: FC<TimeLogApprovalHistoriesProps> = ({
  histories,
  hidden,
  submitterName,
  submittedDate
}) => {
  return (
    <Row hidden={hidden || !(histories.length > 0 || (submittedDate && submitterName))}>
      <Col span={24}>
        <Form.Item label="Approval history">
          {histories.map(h => (
            <div key={h.id} style={divStyles}>
              <b>{h.approverName} </b>
              {h.approverEmployeeNo ? `(${h.approverEmployeeNo})` : ''}{' '}
              {h.approvalStatus === TimeLogStatus.Approved ? 'approved' : 'rejected'} the time log request{' '}
              {getEventDate(h.approvedDate)}
              {h.remarks && (
                <Tooltip title={h.remarks}>
                  <i className="fal fa-comment-dots" style={{ marginLeft: 8, fontWeight: 'bold' }} />
                </Tooltip>
              )}
            </div>
          ))}
          {submittedDate && submitterName && (
            <div style={divStyles}>
              <b>{submitterName}</b> submitted the time log request {getEventDate(submittedDate)}
            </div>
          )}
        </Form.Item>
      </Col>
    </Row>
  )
}

const getEventDate = memoize(date => (date ? ` on ${moment(date).format('DD MMM YYYY hh:mm A')}` : ''))
