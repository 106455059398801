import { showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiDisable2fa } from '../api/two-factor.api'
import { setLogin } from '../reducers'

export const disable2fa = (): ThunkResult<void> => {
  return async (dispatch, getState): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiDisable2fa()
    if (status) {
      const myJwt = getState().iam.myJwt
      const myLogin = getState().iam.logins.entities[myJwt?.loginId || '']

      if (myLogin) {
        dispatch(setLogin({ ...myLogin, isTwoFactorEnabled: false }))
      }
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
