import { EntityState } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { EmSelectState } from '~/components'
import { LocationAdminState, LocationProjectState, LocationState } from './location.state'
import { BreakState, BreakTimeState } from './break.state'
import { ShiftCategoryState } from './shift-category.state'
import { ShiftRoleState, ShiftRoleTeState } from './shift-role.state'
import { ShiftDayState, ShiftState, ShiftTeState } from './shift.state'
import { WorkCalendarState } from './calendar.state'
import { CalendarPatternState } from '~/features/master'
import { OtConfigState } from './ot-config.state'
import { TeConfigState } from './te-config.state'
import {
  DailyRecordByEmployeeViewState,
  DailyRecordLabelState,
  DailyRecordState,
  DailyRecordViewState,
  IDailyRecordViewForm
} from './daily-record.state'
import { TimeLogApprovalHistoryState, TimeLogState, TimeLogViewState } from './time-log.state'
import { AttendancePeriodState, AttendancePeriodViewState } from './attendance-period.state'
import { SysDailyFieldState } from './sys-daily-field.state'
import { TimeLogTemplateState, TimeLogImportState, TimeLogImportValidateState } from './time-log-import.state'
import { DailyPolicyRowState, DailyPolicyState } from './daily-policy.state'
import { SysDailyPolicyFieldState } from './sys-daily-policy-field.state'
import { SysDailyPolicyExpressionState } from './sys-daily-policy-expression.state'
import { AttendanceGroupState } from './attendance-group.state'
import { DailySummaryViewState } from './daily-summary.state'
import { ProjectEmState, ProjectState } from './project.state'

export const attendancePersistConfig = {
  key: 'attendance',
  whitelist: [
    'locations',
    'attendanceGroups',
    'breaks',
    'breakTimes',
    'timeLogTemplates',
    'otConfigs',
    'teConfigs',
    'shiftCategories',
    'shifts',
    'shiftDays',
    'shiftDaysAggregated',
    'calendars',
    'calendarPatterns',
    'sysDailyFields',
    'sysDailyPolicyFields',
    'sysDailyPolicyExpressions',
    'dailyPolicies',
    'dailyPolicyRows'
  ],
  storage
}

export interface AttendanceRootState {
  locations: EntityState<LocationState>
  locationsLoading: boolean

  refetchLocationsView: number
  locationsView: EntityState<LocationState>
  locationsViewLoading: boolean

  locationAdmins: { [id: string]: EntityState<LocationAdminState> }
  locationAdminsLoading: { [id: string]: boolean }

  locationProjects: { [id: string]: EntityState<LocationProjectState> }
  locationProjectsLoading: { [id: string]: boolean }

  attendanceGroups: EntityState<AttendanceGroupState>
  attendanceGroupsLoading: boolean

  breaks: EntityState<BreakState>
  breaksLoading: boolean

  breakTimes: { [breakId: string]: EntityState<BreakTimeState> }
  breakTimesLoading: { [breakId: string]: boolean }

  timeLogTemplates: EntityState<TimeLogTemplateState>
  timeLogTemplatesLoading: boolean

  otConfigs: EntityState<OtConfigState>
  otConfigsLoading: boolean
  teConfigs: EntityState<TeConfigState>
  teConfigsLoading: boolean

  shiftCategories: EntityState<ShiftCategoryState>
  shiftCategoriesLoading: boolean

  shiftRoles: EntityState<ShiftRoleState>
  shiftRolesLoading: boolean

  shiftRoleTes: { [shiftRoleId: string]: EntityState<ShiftRoleTeState> }
  shiftRoleTesLoading: { [shiftRoleId: string]: boolean }

  shifts: EntityState<ShiftState>
  shiftsLoading: boolean

  shiftDaysRefetch: { [shiftId: string]: number }
  shiftDays: { [shiftId: string]: EntityState<ShiftDayState> }
  shiftDaysLoading: { [shiftId: string]: boolean }
  shiftDaysAggregated: { [shiftId: string]: EntityState<ShiftDayState> }
  shiftDaysAggregatedLoading: { [shiftId: string]: boolean }

  shiftTes: { [shiftId: string]: EntityState<ShiftTeState> }
  shiftTesLoading: { [shiftId: string]: boolean }

  calendars: EntityState<WorkCalendarState>
  calendarsLoading: boolean
  calendarPatterns: { [calendarId: string]: EntityState<CalendarPatternState> }
  calendarPatternsLoading: { [calendarId: string]: boolean }

  sysDailyFields: EntityState<SysDailyFieldState>
  sysDailyFieldsLoading: boolean
  sysDailyPolicyFields: EntityState<SysDailyPolicyFieldState>
  sysDailyPolicyFieldsLoading: boolean
  sysDailyPolicyExpressions: EntityState<SysDailyPolicyExpressionState>
  sysDailyPolicyExpressionsLoading: boolean

  dailyPolicies: EntityState<DailyPolicyState>
  dailyPoliciesLoading: boolean
  dailyPolicyRows: { [dailyPolicyId: string]: EntityState<DailyPolicyRowState> }
  dailyPolicyRowsLoading: { [dailyPolicyId: string]: boolean }

  projects: EntityState<ProjectState>
  projectsLoading: boolean
  projectEm: { [id: string]: EntityState<ProjectEmState> }
  projectEmLoading: { [id: string]: boolean }

  attendancePeriodsView: EntityState<AttendancePeriodViewState>
  attendancePeriodsViewLoading: boolean
  attendancePeriodsViewRefetch: number

  attendancePeriods: EntityState<AttendancePeriodState>
  attendancePeriodsLoading: boolean

  attendancePeriodsForPayroll: EntityState<AttendancePeriodState>
  attendancePeriodsForPayrollLoading: boolean

  dailyRecordsView: EntityState<DailyRecordViewState>
  dailyRecordsViewLoading: boolean
  dailyRecordsViewRefetch: number
  dailyRecordsViewForm: IDailyRecordViewForm

  dailyRecords: EntityState<DailyRecordState>
  dailyRecordsLoading: boolean
  dailyRecordsRefetch: number
  dailyProcessing: boolean
  dailyLabelsRefreshing: boolean

  dailyRecordLabels: { [dailyRecordId: string]: EntityState<DailyRecordLabelState> }
  dailyRecordLabelsLoading: { [dailyRecordId: string]: boolean }

  dailyRecordEmployees: EntityState<EmSelectState>
  dailyRecordEmployeesLoading: boolean

  dailySummaryView: EntityState<DailySummaryViewState>
  dailySummaryViewLoading: boolean
  dailySummaryViewRefetch: number

  dailyRecordsByEmployeeView: { [employeeId: string]: EntityState<DailyRecordByEmployeeViewState> }
  dailyRecordsByEmployeeViewLoading: { [employeeId: string]: boolean }
  dailyRecordsByEmployeeViewRefetch: { [employeeId: string]: number }

  timeLogsView: EntityState<TimeLogViewState>
  timeLogsViewLoading: boolean
  timeLogsViewRefetch: number

  timeLogsByDaily: { [dailyRecordId: string]: EntityState<TimeLogState> }
  timeLogsByDailyLoading: { [dailyRecordId: string]: boolean }
  timeLogsByDailyRefetch: { [dailyRecordId: string]: number }

  timeLogImport?: TimeLogImportState
  timeLogImportValidate?: TimeLogImportValidateState

  timeLogApprovalHistories: { [id: string]: EntityState<TimeLogApprovalHistoryState> }
  timeLogApprovalHistoriesLoading: { [id: string]: boolean }
}
