import { FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { RangeValue } from 'rc-picker/lib/interface.d'
import { useSelector } from 'react-redux'
import { DrawerForm } from '~/components'
import { Form, Input } from '~/core-components'
import { EmSelection } from '~/features/employee'
import { useFocus } from '~/hooks'
import { dispatch } from '~/stores/store'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { Pagination } from '~/types/common'
import { fetchDailyEmSelections, processDaily } from '../../../actions'
import { refetchDailyRecordsView } from '../../../reducers'
import './ProcessDailyDrawer.less'

export interface ProcessDailyDrawerProps {
  visible: boolean
  startDate?: string | null
  endDate?: string | null
  minDate?: string
  maxDate?: string
  employeeIds?: string[]
  onClose: () => void
}

interface FormData {
  startDate: string | null
  endDate: string | null
  employeeIds?: string[]
}

const EMPTY_FORM_DATA: FormData = {
  startDate: '',
  endDate: '',
  employeeIds: []
}

export const ProcessDailyDrawer: FC<ProcessDailyDrawerProps> = ({
  visible,
  startDate = '',
  endDate = '',
  minDate,
  maxDate,
  employeeIds = [],
  onClose
}) => {
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [errors, setErrors] = useState<Errors>()
  const processing = useSelector((state: StoreState) => state.attendance.dailyProcessing)

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (visible) {
      setFormData({ ...EMPTY_FORM_DATA, startDate, endDate, employeeIds })
    }
  }, [visible, startDate, endDate, employeeIds])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    if (!formData.startDate || !formData.endDate) {
      console.warn('Start date and end date are required')
      return
    }

    const result: ActionResult | undefined = await dispatch(
      processDaily(formData.startDate, formData.endDate, formData.employeeIds, 'all')
    )
    dispatch(refetchDailyRecordsView())
    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
    }
  }, [formData, onClose])

  const handleFetchEmployees = useCallback(
    (viewId: string, pagination: Pagination, search: string) => {
      if (visible) {
        if (!formData.startDate || !formData.endDate) {
          console.warn('Start date and end date are required')
          return
        }

        dispatch(fetchDailyEmSelections(formData.startDate, formData.endDate, viewId, pagination, search))
      }
    },
    [visible, formData]
  )

  const handleSelectEmployees = useCallback(
    (employeeIds: string[]) => {
      handleFormDataChange({ employeeIds })
    },
    [handleFormDataChange]
  )

  return (
    <DrawerForm
      open={visible}
      title="Recalculate daily record"
      okText="Recalculate"
      onOk={handleOk}
      onClose={onClose}
      confirmLoading={processing}
      width={700}
      className="process-daily-drawer"
    >
      <Form className="process-daily-form">
        <div className="process-daily-form__header">
          <Form.Item
            label="Date range"
            validateStatus={errors?.startDate || errors?.endDate ? 'error' : ''}
            help={errors?.startDate || errors?.endDate}
          >
            <Input.DateRange
              ref={focusRef}
              allowClear={false}
              value={[
                formData.startDate ? moment(formData.startDate) : null,
                formData.endDate ? moment(formData.endDate) : null
              ]}
              onCalendarChange={(dates: RangeValue<moment.Moment>) => {
                const startDate = dates && dates[0] ? dates[0].format('YYYY-MM-DD') : null
                const endDate = dates && dates[1] ? dates[1].format('YYYY-MM-DD') : null
                handleFormDataChange({ startDate, endDate })
              }}
              disabledDate={current =>
                !!current && !!minDate && !!maxDate && (current > moment(maxDate) || current < moment(minDate))
              }
            />
          </Form.Item>
        </div>
        <div className="process-daily-form__body">
          <EmSelection
            viewName="daily_process"
            visible={visible}
            employeeIds={employeeIds}
            onFetchData={handleFetchEmployees}
            onSelect={handleSelectEmployees}
            resetOnClose={true}
          />
        </div>
      </Form>
    </DrawerForm>
  )
}
