import React, { FC, useCallback, useState } from 'react'
import moment from 'moment-timezone'
import { RangeValue } from 'rc-picker/lib/interface.d'
import { Form, Input } from '~/core-components'
import { Col, DrawerForm, Row } from '~/components'
import { dispatch } from '~/stores/store'
import { synchronizeGoogleCalendar } from '~/features/leave/actions'
import { DateRange } from '~/types/common'

interface GoogleCalendarSyncDrawerProps {
  open: boolean
  id?: string
  onClose: () => void
}

interface FormData extends DateRange {}

const EMPTY_FORM_DATA: FormData = {
  startDate: moment().startOf('month').format('YYYY-MM-DD'),
  endDate: moment().endOf('month').format('YYYY-MM-DD')
}

export const GoogleCalendarSyncDrawer: FC<GoogleCalendarSyncDrawerProps> = ({ open, id, onClose }) => {
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [syncing, setSyncing] = useState(false)

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleSynchronize = useCallback(async () => {
    if (id) {
      try {
        setSyncing(true)
        await dispatch(synchronizeGoogleCalendar(id, formData))
        typeof onClose === 'function' && onClose()
        setFormData(EMPTY_FORM_DATA)
      } finally {
        setSyncing(false)
      }
    }
  }, [id, formData, onClose])

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose()
  }, [onClose])

  return (
    <DrawerForm
      open={open}
      title="Synchronize"
      okText="Sync now"
      onClose={handleCloseDrawer}
      confirmLoading={syncing}
      width={500}
      className="google-calendar-sync-drawer"
      formId="form-google-calendar-sync"
    >
      <Form id="form-google-calendar-sync" onFinish={handleSynchronize}>
        <Row>
          <Col span={24}>
            <Form.Item label="Dates">
              <Input.DateRange
                allowClear={false}
                value={[
                  formData.startDate ? moment(formData.startDate) : null,
                  formData.endDate ? moment(formData.endDate) : null
                ]}
                onCalendarChange={(dates: RangeValue<moment.Moment>) => {
                  const startDate = dates && dates[0] ? dates[0].format('YYYY-MM-DD') : ''
                  const endDate = dates && dates[1] ? dates[1].format('YYYY-MM-DD') : ''
                  handleFormDataChange({ startDate, endDate })
                }}
                disabledDate={current => current && current < moment().subtract(30, 'days').startOf('day')}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
