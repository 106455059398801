import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '~/stores/store'
import { FetchStrategy } from '~/types/common'
import { StoreState } from '~/types/store'
import { fetchYtdRecordView } from '../actions'
import { YtdRecordViewState } from '../types'

export const useYtdRecordView = (
  viewId: string,
  ytdYear: string,
  companyId: string,
  page: number,
  pageSize: number,
  search: string = '',
  fetchStrategy: FetchStrategy = 'when-empty'
): [YtdRecordViewState | undefined, boolean] => {
  const data = useSelector((state: StoreState) => state.tax.ytdRecordView?.entities[viewId])
  const loading = useSelector((state: StoreState) => state.tax.ytdRecordViewLoading)
  const refetch = useSelector((state: StoreState) => state.tax.ytdRecordViewRefetch)

  useEffect(() => {
    if (viewId && ytdYear && companyId)
      dispatch(
        fetchYtdRecordView(viewId, ytdYear, companyId, { offset: pageSize * (page - 1), limit: pageSize }, search, {
          strategy: fetchStrategy
        })
      )
  }, [viewId, ytdYear, companyId, page, pageSize, search, refetch, fetchStrategy])

  return [data, loading]
}
