import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { EditOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import moment from 'moment-timezone'
import { Button, Card, CardProps, Form, SecondaryLink, Space, Tooltip } from '~/core-components'
import { Col, NewTabLink, Row } from '~/components'
import { useCompany, usePayGroup } from '~/features/master'
import { IRptBankFile, selectSysBankFileById } from '~/features/report'
import { usePermissionGate } from '~/features/iam'
import { emptyGuid, PayRunStatus, Permission, SysBankFile } from '~/constants'
import { useIsMountedRef } from '~/hooks'
import { REPORTS_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { Errors, StoreState } from '~/types/store'
import { downloadWithDom, getFileTimestamp, showError } from '~/utils'
import { selectPayRunById, setPayRun } from '../../reducers'
import { apiGetBankFile } from '../../../report/api/rpt-bank-file.api'
import { SelectBankFileDrawer } from '../../../report/containers/RptBankFile/components/SelectBankFileDrawer'
import { BankFileUobFastGiroJsonb } from '../../../report/containers/RptBankFile/components/BankFileUobFastGiroJsonb'
import { BankFileDbsInterbankGiroJsonb } from '../../../report/containers/RptBankFile/components/BankFileDbsInterbankGiroJsonb'
import { BankFileOcbcVelocityGiroJsonb } from '../../../report/containers/RptBankFile/components/BankFileOcbcVelocityGiroJsonb'
import { BankFileDbsUffFastJsonb } from '~/features/report/containers/RptBankFile/components/BankFileDbsUffFastJsonb'
import { BankFileMaybankApsJsonb } from '~/features/report/containers/RptBankFile/components/BankFileMaybankApsJsonb'
import './BankFileCard.less'

export interface BankFileCardProps extends CardProps {
  payRunId: string
}

interface BankFileFormData {
  sysBankFileId: string
  valueDate: string
  batchNo: string
  bankFileJsonb: string
}

const EMPTY_FORM_DATA: BankFileFormData = {
  sysBankFileId: '',
  valueDate: '',
  batchNo: '',
  bankFileJsonb: ''
}

interface DrawerState {
  visible: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

export const BankFileCard: FC<BankFileCardProps> = ({ payRunId, className, ...props }) => {
  const [formData, setFormData] = useState<BankFileFormData>(EMPTY_FORM_DATA)
  const [downloading, setDownloading] = useState(false)
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)
  const [errors, setErrors] = useState<Errors>()
  const payRun = useSelector((state: StoreState) => selectPayRunById(state, payRunId))
  const [payGroup] = usePayGroup(payRun?.payGroupId || '')
  const [company] = useCompany(payGroup?.companyId || '')
  const sysBankFile = useSelector((state: StoreState) => selectSysBankFileById(state, formData.sysBankFileId))
  const isMountedRef = useIsMountedRef()
  const canModify = payRun?.status !== PayRunStatus.completed
  const canView = usePermissionGate(Permission.rptBankFile)

  useEffect(() => {
    if (payRun) {
      setFormData({
        sysBankFileId: payRun.sysBankFileId || '',
        valueDate: payRun.valueDate || '',
        batchNo: payRun.batchNo || '',
        bankFileJsonb: payRun.bankFileJsonb || ''
      })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [payRun])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleDownloadClick = useCallback(async () => {
    try {
      setDownloading(true)
      setErrors(undefined)

      const { sysBankFileId, valueDate, batchNo, bankFileJsonb } = formData

      const params: IRptBankFile = {
        sysBankFileId: sysBankFileId || emptyGuid,
        valueDate,
        batchNo,
        bankFileJsonb
      }
      const { status, result, errors, message, errorData } = await apiGetBankFile(payRunId, params)

      if (errors) {
        setErrors(errors)
      }

      if (status) {
        let filename = `bank_file_${getFileTimestamp()}.zip`
        if (sysBankFileId === SysBankFile.UobFastGiro) {
          let batchNoFormatted = '01'
          if (batchNo !== '') {
            batchNoFormatted = batchNo
          }

          filename = 'UGBI' + moment().format('DDMM') + batchNoFormatted
        }
        downloadWithDom(result, filename)

        if (payRun) {
          dispatch(setPayRun({ ...payRun, sysBankFileId, valueDate, batchNo, bankFileJsonb }))
        }
      } else {
        console.error('Error while downloading', errors)
        showError(message, errorData)
      }
    } finally {
      if (isMountedRef.current) setDownloading(false)
    }
  }, [payRunId, payRun, formData, isMountedRef])

  const handleOpenDrawer = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(
    (sysBankFileId?: string) => {
      if (sysBankFileId) {
        let bankFileJsonb = ''
        if (sysBankFileId === payRun?.sysBankFileId) bankFileJsonb = payRun.bankFileJsonb || ''

        handleFormDataChange({ sysBankFileId, bankFileJsonb })
      }
      setDrawerState(DEFAULT_DRAWER_STATE)
      setErrors(undefined)
    },
    [payRun, handleFormDataChange]
  )

  if (!canView) return null

  return (
    <Card className={classNames('bank-file-card', className)} {...props}>
      <Form className="bank-file-card__formbank" layout="horizontal" labelCol={{ flex: '120px' }} colon={false}>
        <Row className="bank-file-card__title">
          <Col flex="auto">
            <Form.Item label="" validateStatus={errors?.sysBankFileId ? 'error' : ''} help={errors?.sysBankFileId}>
              <b>Bank file</b>
            </Form.Item>
          </Col>
          <Col>
            <Space>
              <div className="bank-file-card__choice">{sysBankFile?.name || ''}</div>
              {canModify && (
                <Tooltip title="Select bank file">
                  <SecondaryLink onClick={handleOpenDrawer}>
                    <EditOutlined />
                  </SecondaryLink>
                </Tooltip>
              )}
            </Space>
          </Col>
        </Row>
        {formData.sysBankFileId === SysBankFile.OcbcVelocityGiroFast && (
          <BankFileOcbcVelocityGiroJsonb
            valueDate={formData.valueDate}
            batchNo={formData.batchNo}
            bankFileJsonb={formData.bankFileJsonb}
            onChange={(key: string, value: string) => handleFormDataChange({ [key]: value })}
            canModify={canModify}
            errors={errors}
          />
        )}
        {formData.sysBankFileId === SysBankFile.UobFastGiro && (
          <BankFileUobFastGiroJsonb
            bankCompanyId={company?.bankCompanyId}
            valueDate={formData.valueDate}
            batchNo={formData.batchNo}
            bankFileJsonb={formData.bankFileJsonb}
            onChange={(key: string, value: string) => handleFormDataChange({ [key]: value })}
            canModify={canModify}
            errors={errors}
            dropdownStyle={{ width: 163 }}
          />
        )}
        {formData.sysBankFileId === SysBankFile.DbsInterbankGiro && (
          <BankFileDbsInterbankGiroJsonb
            bankCompanyId={company?.bankCompanyId}
            valueDate={formData.valueDate}
            batchNo={formData.batchNo}
            bankFileJsonb={formData.bankFileJsonb}
            onChange={(key: string, value: string) => handleFormDataChange({ [key]: value })}
            canModify={canModify}
            errors={errors}
            dropdownStyle={{ width: 163 }}
          />
        )}
        {formData.sysBankFileId === SysBankFile.DbsUffFast && (
          <BankFileDbsUffFastJsonb
            bankCompanyId={company?.bankCompanyId}
            valueDate={formData.valueDate}
            batchNo={formData.batchNo}
            bankFileJsonb={formData.bankFileJsonb}
            onChange={(key: string, value: string) => handleFormDataChange({ [key]: value })}
            canModify={canModify}
            errors={errors}
          />
        )}
        {formData.sysBankFileId === SysBankFile.MaybankAps && (
          <BankFileMaybankApsJsonb
            bankFileJsonb={formData.bankFileJsonb}
            onChange={(key: string, value: string) => handleFormDataChange({ [key]: value })}
            canModify={canModify}
            errors={errors}
            dropdownStyle={{ width: 163 }}
          />
        )}
        <Row wrap={false}>
          <Col flex={1} className="bank-file-card__download-selective">
            <NewTabLink
              path={`${REPORTS_ROUTES.bankFile}?id=${payRunId}`}
              tooltipText="Download bank file generation for selective employees in a new tab"
            >
              Download selective employees
            </NewTabLink>
          </Col>
          <Col>
            <Button
              type="primary"
              size="small"
              onClick={handleDownloadClick}
              loading={downloading}
              className="bank-file-card__download-button"
            >
              Download
            </Button>
          </Col>
        </Row>
      </Form>
      {canModify && <SelectBankFileDrawer {...drawerState} onClose={handleCloseDrawer} />}
    </Card>
  )
}
