import { FC, useMemo } from 'react'
import classNames from 'classnames'
import { Card, Skeleton, Space } from '~/core-components'
import { TimeDayDisplay } from '~/components'
import { useSysOptions } from '~/features/employee'
import { getDayCode, useLocationsDict, useShiftDaysDict, useShiftRolesDict, useShiftsDict } from '~/features/attendance'
import { NON_WORKING_STATUS_TYPE, WorkStatusType } from '~/constants'
import { getTextColor } from '~/utils'
import { ScheduleRecordViewDataState } from '../../../types'
import './ScheduledShiftDay.less'

export interface ScheduledShiftDayProps {
  schedule?: ScheduleRecordViewDataState
  showLocation?: boolean
  showShiftRole?: boolean
  loading?: boolean
  readOnly?: boolean
  onClick: () => void
}

export const ScheduledShiftDay: FC<ScheduledShiftDayProps> = ({
  schedule,
  showLocation,
  showShiftRole,
  readOnly,
  loading,
  onClick
}) => {
  const [workStatusDict] = useSysOptions('work_status')
  const [shiftsDict] = useShiftsDict()
  const [shiftDaysDict] = useShiftDaysDict(schedule?.shiftId || '')
  const [locationsDict] = useLocationsDict()
  const [shiftRolesDict] = useShiftRolesDict()

  const classes = classNames(
    'scheduled-shift-day',
    { 'scheduled-shift-day--readonly': readOnly },
    { [`scheduled-shift-day__work-status-${schedule?.workStatus}`]: schedule?.workStatus != null }
  )

  const isHoliday = false
  const shift = shiftsDict[schedule?.shiftId || '']
  const dayCode = getDayCode(schedule?.scheduleDate || '', schedule?.workStatus || '', isHoliday, schedule?.shiftId)
  const shiftDay = shiftDaysDict[dayCode]
  const workStatus = schedule?.workStatus || ''
  const isNonWorkingStatusType = NON_WORKING_STATUS_TYPE.includes(workStatus as WorkStatusType)

  const shiftColor = shift?.color || '#ffffff'
  const textColor = useMemo(() => getTextColor(shiftColor), [shiftColor])
  const shiftCardStyle = useMemo(
    () => (isNonWorkingStatusType ? {} : { backgroundColor: shiftColor, color: textColor }),
    [isNonWorkingStatusType, shiftColor, textColor]
  )

  if (!schedule) return null

  if (loading) return <Skeleton.Input size="small" active />

  return (
    <Card className={classes} style={shiftCardStyle} onClick={onClick}>
      {isNonWorkingStatusType && !schedule.shiftId && workStatusDict[workStatus]?.value}
      {schedule.shiftId && (
        <>
          <div className="scheduled-shift-day__name">
            {isNonWorkingStatusType && `${workStatusDict[workStatus]?.value}, `}
            {shift?.name}
          </div>
          {(shiftDay?.normalStartTime || shiftDay?.normalEndTime) && (
            <div className="scheduled-shift-day__time">
              <Space>
                <TimeDayDisplay time={shiftDay?.normalStartTime} day={shiftDay?.normalStartDay} />
                <span>-</span>
                <TimeDayDisplay time={shiftDay?.normalEndTime} day={shiftDay?.normalEndDay} />
              </Space>
            </div>
          )}
        </>
      )}
      {showLocation && schedule?.locationId && (
        <div className="scheduled-shift-day__location">
          <i className="fal fa-location-dot" /> {locationsDict[schedule.locationId]?.name}
        </div>
      )}
      {showShiftRole && schedule?.shiftRoleId && (
        <div className="scheduled-shift-day__shift-role">{shiftRolesDict[schedule.shiftRoleId]?.name}</div>
      )}
    </Card>
  )
}
