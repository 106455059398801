import { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment-timezone'
import pick from 'lodash/pick'
import { v4 as uuidv4 } from 'uuid'
import { ColumnsType, Form, Input, SecondaryText, Space, Table } from '~/core-components'
import { Col, DayIndicator, DrawerForm, ErrorDisplay, Row, TimeDayInput } from '~/components'
import {
  TimeLogRequestData,
  TimeLogApprovalHistories,
  TimeLogCurrentApprover,
  useLocationsDict,
  useProjectsDict
} from '~/features/attendance'
import { useMyEmployee } from '~/features/my'
import { TimeLogStatus } from '~/constants'
import { ActionResult, Errors } from '~/types/store'
import { dispatch } from '~/stores/store'
import { updateMyTimeLog } from '../../actions'
import { ISSTimeLogEdit, SSTimeLogState } from '../../types'
import { useMyTimeLogApprovalHistories } from '../../hooks'
import './MyTimeLogEditDrawer.less'

interface MyTimeLogEditProps {
  visible: boolean
  data?: SSTimeLogState
  editing?: boolean
  onClose: (action: 'saved' | 'cancelled') => void
}

const EMPTY_FORM_DATA: ISSTimeLogEdit = {
  id: ''
}

const TODAY = moment().format('YYYY-MM-DD')

export const MyTimeLogEditDrawer: FC<MyTimeLogEditProps> = ({ visible, data, editing, onClose }) => {
  const [submitting, setSubmitting] = useState(false)
  const [formData, setFormData] = useState<ISSTimeLogEdit>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [isEditing, setIsEditing] = useState(false)

  const [me] = useMyEmployee()
  const [locationDict] = useLocationsDict()
  const [projectDict] = useProjectsDict()
  const [approvalHistories] = useMyTimeLogApprovalHistories(data?.id)

  const errorRef = useRef<any>(null)
  const formId = useMemo(() => `form-my-time-log-edit-${uuidv4()}`, [])

  const isPending = !!data?.timeInRequest && !!data?.timeOutRequest
  const isOngoing = !!data?.timeInRequest && !data?.timeOutRequest

  // TODO: Non-editable when daily record is approved
  const isEditable = !isOngoing && me.attributes['time_log_edit'] === true

  useEffect(() => {
    setErrors(undefined)

    if (!visible) {
      setIsEditing(false)
    }
  }, [visible])

  useEffect(() => {
    if (visible) {
      setIsEditing(!!isEditable && !!editing)
    }
  }, [visible, isEditable, editing])

  useEffect(() => {
    if (data) {
      const { id, timeIn, timeInRequest, timeOut, timeOutRequest } = data
      setFormData({ id, timeInRequest: timeInRequest ?? timeIn, timeOutRequest: timeOutRequest ?? timeOut })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  const handleFormDataChange = useCallback((updated: Partial<ISSTimeLogEdit>) => {
    setErrors(undefined)
    setFormData(formData => ({ ...formData, ...updated }))
  }, [])

  const handleToggleEdit = useCallback(() => {
    setIsEditing(isEditing => !isEditing)
  }, [])

  const handleOk = useCallback(async () => {
    if (!formData) return

    let result: ActionResult | undefined
    setSubmitting(true)
    try {
      result = await dispatch(updateMyTimeLog(formData))
    } finally {
      setSubmitting(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
      errorRef.current?.scrollIntoView()
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose('saved')
      setFormData(EMPTY_FORM_DATA)
    }
  }, [formData, onClose])

  const onSubmit = useCallback(() => {
    if (!isEditable) {
      return onClose('cancelled')
    }

    if (isEditing) {
      return handleOk()
    } else {
      return handleToggleEdit()
    }
  }, [isEditable, isEditing, onClose, handleOk, handleToggleEdit])

  const handleCloseDrawer = useCallback(() => {
    onClose('cancelled')
  }, [onClose])

  const okText = useMemo(() => {
    if (!isEditable) {
      return 'Close'
    }

    if (isEditing) {
      return (
        <Space>
          Send request <i className="fal fa-paper-plane-top" />
        </Space>
      )
    }

    return 'Edit'
  }, [isEditable, isEditing])

  const requestData: TimeLogRequestData<ISSTimeLogEdit>[] = useMemo(
    () => [
      {
        field: 'timeInRequest',
        label: 'Time in',
        current: data?.timeIn,
        request: formData.timeInRequest
      },
      {
        field: 'timeOutRequest',
        label: 'Time out',
        current: data?.timeOut,
        request: formData.timeOutRequest,
        currentTimeIn: data?.timeIn,
        requestTimeIn: formData.timeInRequest
      }
    ],
    [data, formData]
  )

  const columns: ColumnsType<TimeLogRequestData<ISSTimeLogEdit>> = useMemo(
    () => [
      {
        key: 'label',
        dataIndex: 'label',
        width: 150
      },
      {
        title: 'Current',
        key: 'current',
        dataIndex: 'current',
        render: (value: string, record: TimeLogRequestData<ISSTimeLogEdit>) =>
          value ? (
            record.field === 'timeOutRequest' ? (
              <TimeDayInput
                time={moment(value)}
                day={
                  record.currentTimeIn ? (moment(value).diff(moment(record.currentTimeIn), 'day') as DayIndicator) : 0
                }
                readOnly
                readOnlyBorderless
              />
            ) : (
              moment(value).format('HH:mm')
            )
          ) : null
      },
      {
        align: 'center',
        render: () => <i className="fa-light fa-arrow-right-long" style={{ color: '#3caef2' }} />
      },
      {
        title: 'Change to',
        key: 'request',
        dataIndex: 'request',
        render: (value: string, record: TimeLogRequestData<ISSTimeLogEdit>) =>
          isEditing ? (
            record.field === 'timeOutRequest' ? (
              <TimeDayInput
                time={value ? moment(value) : null}
                day={
                  value && record.requestTimeIn
                    ? (moment(value).diff(moment(record.requestTimeIn), 'day') as DayIndicator)
                    : 0
                }
                onChange={(value, day) =>
                  handleFormDataChange({
                    [record.field]: moment(
                      `${moment(record.requestTimeIn).format('YYYY-MM-DD')}T${value?.format('HH:mm') || '00:00'}`
                    )
                      .add(day, 'day')
                      .format('YYYY-MM-DDTHH:mm')
                  })
                }
              />
            ) : (
              <Input.Time
                value={value ? moment(value) : null}
                onChange={(value: moment.Moment | null) =>
                  handleFormDataChange({
                    [record.field]: `${moment(formData[record.field]).format('YYYY-MM-DD')}T${
                      value?.format('HH:mm') || '00:00'
                    }`
                  })
                }
              />
            )
          ) : record.field === 'timeOutRequest' ? (
            <TimeDayInput
              time={value ? moment(value) : null}
              day={
                value && record.requestTimeIn
                  ? (moment(value).diff(moment(record.requestTimeIn), 'day') as DayIndicator)
                  : 0
              }
              readOnly
              readOnlyBorderless
            />
          ) : (
            moment(value).format('HH:mm')
          )
      }
    ],
    [isEditing, formData, handleFormDataChange]
  )

  if (!data) return null

  return (
    <DrawerForm
      className="my-time-log-edit-drawer"
      open={visible}
      title="Time log"
      okText={okText}
      okDisabled={submitting}
      onClose={handleCloseDrawer}
      confirmLoading={submitting}
      width={500}
      formId={formId}
    >
      <Form id={formId} onFinish={onSubmit}>
        <Row>
          <Col flex="200px">
            <Form.Item label="Date">{moment(data.timeIn).format('DD MMM YYYY')}</Form.Item>
          </Col>
        </Row>
        {!(isPending || isOngoing) ? (
          isEditing ? (
            <Row>
              <Col span={12}>
                <Form.Item
                  label="Time in"
                  validateStatus={errors?.timeInRequest ? 'error' : ''}
                  help={errors?.timeInRequest}
                >
                  <Input.Time
                    allowClear
                    value={formData.timeInRequest ? moment(formData.timeInRequest) : undefined}
                    onChange={(value: moment.Moment | null) =>
                      handleFormDataChange({
                        timeInRequest: `${moment(formData.timeInRequest || TODAY).format('YYYY-MM-DD')}T${
                          value?.format('HH:mm') || '00:00'
                        }`
                      })
                    }
                  />
                  {data?.timeInOriginal && (
                    <SecondaryText block size="small">
                      Original: {moment(data.timeInOriginal).format('HH:mm')}
                    </SecondaryText>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Time out" validateStatus={errors?.timeOut ? 'error' : ''} help={errors?.timeOut}>
                  <TimeDayInput
                    date={formData.timeOutRequest ? moment(formData.timeOutRequest).format('YYYY-MM-DD') : undefined}
                    time={formData.timeOutRequest ? moment(formData.timeOutRequest) : undefined}
                    day={
                      formData.timeOutRequest
                        ? (moment(formData.timeOutRequest)
                            .startOf('day')
                            .diff(moment(formData.timeInRequest).startOf('day'), 'day') as DayIndicator)
                        : 0
                    }
                    onChange={(value, day) =>
                      handleFormDataChange({
                        timeOutRequest: moment(
                          `${moment(formData.timeInRequest).format('YYYY-MM-DD')}T${value?.format('HH:mm') || '00:00'}`
                        )
                          .add(day, 'day')
                          .format('YYYY-MM-DDTHH:mm')
                      })
                    }
                  />
                  {data?.timeOutOriginal && (
                    <SecondaryText block size="small">
                      Original: {moment(data.timeOutOriginal).format('HH:mm')}
                    </SecondaryText>
                  )}
                </Form.Item>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col span={12}>
                <Form.Item label="Time in">
                  {data.timeIn ? moment(data.timeIn).format('HH:mm') : ''}
                  {data?.timeInOriginal && (
                    <SecondaryText block size="small">
                      Original: {moment(data.timeInOriginal).format('HH:mm')}
                    </SecondaryText>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Time out">
                  {data.timeOut ? moment(data.timeOut).format('HH:mm') : ''}
                  {data?.timeOutOriginal && (
                    <SecondaryText block size="small">
                      Original: {moment(data.timeOutOriginal).format('HH:mm')}
                    </SecondaryText>
                  )}
                </Form.Item>
              </Col>
            </Row>
          )
        ) : (
          <div className="my-time-log-edit-request">
            <Table rowKey="field" dataSource={requestData} columns={columns} pagination={false} />
          </div>
        )}
        <Row>
          <Col span={24}>
            <Form.Item label="Location">{locationDict[data.locationId]?.name}</Form.Item>
          </Col>
        </Row>
        <Row hidden={!data.projectId}>
          <Col span={24}>
            <Form.Item label="Project">{projectDict[data.projectId || '']?.name}</Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {isEditing ? (
              <Form.Item label="Notes" validateStatus={errors?.notes ? 'error' : ''} help={errors?.notes}>
                <Input.TextArea
                  rows={2}
                  value={formData.notes}
                  onChange={(value?: ChangeEvent<HTMLTextAreaElement>) =>
                    handleFormDataChange({ notes: value?.target.value })
                  }
                />
              </Form.Item>
            ) : (
              <Form.Item label="Notes"> {data.notes || '-'}</Form.Item>
            )}
          </Col>
        </Row>
        <ErrorDisplay ref={errorRef} errors={errors} />
        <TimeLogCurrentApprover
          approvers={data?.currentApprovers || []}
          hidden={data?.approvalStatus !== TimeLogStatus.Pending}
        />
        <TimeLogApprovalHistories histories={approvalHistories} {...pick(data, 'submitterName', 'submittedDate')} />
      </Form>
    </DrawerForm>
  )
}
