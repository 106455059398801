import React, { FC, useCallback } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Col, DocumentTitle, Row } from '~/components'
import { TabTitle, Tabs } from '~/core-components'
import { TAX_ROUTES } from '~/routes/routes'
import { dispatch } from '~/stores/store'
import { refetchIr21SubmissionViews, refetchYtdSubmissionViews } from '../../reducers'
import { Ir21Submissions } from '../Ir21Submissions/Ir21Submissions'
import { YtdSubmissions } from '../YtdSubmissions/YtdSubmissions'
import { YtdIndex } from '../YtdIndex/YtdIndex'
import { YtdRecords } from '../YtdRecords/YtdRecords'
import './Taxation.less'

interface TaxationProps {}

export const Taxation: FC<TaxationProps> = () => {
  const match = useRouteMatch<{ tab: string }>()
  const history = useHistory()

  const handleTabChange = useCallback(
    (activeKey: string) => {
      history.push(`${TAX_ROUTES.tab.replace(':tab?', activeKey)}`)

      if (activeKey === 'submission') {
        dispatch(refetchYtdSubmissionViews())
        dispatch(refetchIr21SubmissionViews())
      }
    },
    [history]
  )

  return (
    <div className="taxation">
      <DocumentTitle title="Taxation" />
      <div className="taxation__body">
        <Tabs
          defaultActiveKey={match.params.tab || 'submission'}
          onChange={handleTabChange}
          tabBarExtraContent={{
            left: <TabTitle title="Taxation" />
          }}
          items={[
            {
              key: 'submission',
              label: 'Submission',
              children: (
                <Row gutter={24} style={{ margin: '0 4px' }}>
                  <Col lg={24} xl={12}>
                    <YtdSubmissions />
                  </Col>
                  <Col lg={24} xl={12}>
                    <Ir21Submissions />
                  </Col>
                </Row>
              )
            },
            { key: 'record', label: 'YTD records', children: <YtdRecords /> },
            { key: 'publish', label: 'Publish', children: <YtdIndex /> }
          ]}
        />
      </div>
    </div>
  )
}
