import React, { FC, useCallback, useState } from 'react'
import classNames from 'classnames'
import { Button, ButtonProps } from '~/core-components'
import { FeedbackDrawer } from './FeedbackDrawer'

interface FeedbackButtonProps extends ButtonProps {}

interface DrawerState {
  visible: boolean
}

const DEFAULT_DRAWER_STATE: DrawerState = { visible: false }

export const FeedbackButton: FC<FeedbackButtonProps> = ({ className, ...props }) => {
  const classes = classNames('feedback-button', className)
  const [drawerState, setDrawerState] = useState<DrawerState>(DEFAULT_DRAWER_STATE)

  const handleSendFeedback = useCallback(() => {
    setDrawerState({ visible: true })
  }, [])

  const handleCloseDrawer = useCallback(() => setDrawerState(DEFAULT_DRAWER_STATE), [])

  return (
    <>
      <Button className={classes} icon={<i className="fal fa-bullhorn" />} size="small" onClick={handleSendFeedback}>
        Send feedback
      </Button>
      <FeedbackDrawer {...drawerState} onClose={handleCloseDrawer} />
    </>
  )
}
