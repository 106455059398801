import React, { FC } from 'react'
import { default as AntModal, ModalProps as AntModalProps } from 'antd/lib/modal'
import { Button, ButtonProps } from '../Button/Button'
import 'antd/es/modal/style'
import './Modal.less'

export interface ModalProps extends AntModalProps {
  onDelete?: () => void
  formId?: string
}

export const Modal: FC<ModalProps> = ({ onDelete, confirmLoading, formId, okText = 'Save', ...props }: ModalProps) => {
  const submitProps: Partial<ButtonProps> = formId ? { form: formId, htmlType: 'submit' } : { onClick: props.onOk }
  const footer: React.ReactNode =
    formId && okText ? (
      <div className="modal__footer">
        <Button type="primary" loading={confirmLoading} {...submitProps}>
          {okText}
        </Button>
      </div>
    ) : null

  return <AntModal maskClosable={false} footer={footer} {...props} />
}
