import { Dictionary } from '@reduxjs/toolkit'
import { setIr8aForm, setIr8aFormLoading } from '../reducers'
import { apiGetIr8aFormData } from '../api/ytd.api'
import { ThunkResult } from '~/types/store'
import { Ir8aFormState } from '../types'
import { KeyValue } from '~/types/common'

export const fetchIr8aForm = (
  ytdYear: string,
  employerTaxNo: string,
  employeeTaxNo: string,
  ytdSubmissionId: string
): ThunkResult<void> => {
  return async (dispatch, getState) => {
    try {
      dispatch(setIr8aFormLoading(true))
      const { result, status } = await apiGetIr8aFormData(ytdYear, employerTaxNo, employeeTaxNo, ytdSubmissionId)
      if (status) {
        const countries = getState().master.keyvalues['country']?.entities
        const stored = mapToState(result, countries)
        dispatch(setIr8aForm(stored))
      }
    } finally {
      dispatch(setIr8aFormLoading(false))
    }
  }
}

const mapToState = (ir8aForm: Ir8aFormState, countries: Dictionary<KeyValue>): Ir8aFormState => {
  ir8aForm.addressCountryName = countries ? countries[ir8aForm.addressCountryCode || '']?.value || '' : ''
  ir8aForm.cpfEmTotal = ir8aForm.cpfEm + ir8aForm.cpfEmAdj
  ir8aForm.cpfErTotal = ir8aForm.cpfEr + ir8aForm.cpfErAdj
  ir8aForm.cpfErOverseasTotal = ir8aForm.cpfErOverseas + ir8aForm.cpfErOverseasAdj
  ir8aForm.cpfErVoluntaryTotal = ir8aForm.cpfErVoluntary + ir8aForm.cpfErVoluntaryAdj
  ir8aForm.fundCdacTotal = ir8aForm.fundCdac + ir8aForm.fundCdacAdj
  ir8aForm.fundComchestTotal = ir8aForm.fundComchest + ir8aForm.fundComchestAdj
  ir8aForm.fundEcfTotal = ir8aForm.fundEcf + ir8aForm.fundEcfAdj
  ir8aForm.fundMbfTotal = ir8aForm.fundMbf + ir8aForm.fundMbfAdj
  ir8aForm.fundMendakiTotal = ir8aForm.fundMendaki + ir8aForm.fundMendakiAdj
  ir8aForm.fundOtherTotal = ir8aForm.fundOther + ir8aForm.fundOtherAdj
  ir8aForm.fundSindaTotal = ir8aForm.fundSinda + ir8aForm.fundSindaAdj
  ir8aForm.grossTotal = ir8aForm.grossAmt + ir8aForm.grossAdj
  ir8aForm.bonusTotal = ir8aForm.bonusAmt + ir8aForm.bonusAdj
  ir8aForm.commissionTotal = ir8aForm.commissionAmt + ir8aForm.commissionAdj
  ir8aForm.compensationTotal = ir8aForm.compensationAmt + ir8aForm.compensationAdj
  ir8aForm.directorFeeTotal = ir8aForm.directorFeeAmt + ir8aForm.directorFeeAdj
  ir8aForm.entertainmentTotal = ir8aForm.entertainmentAmt + ir8aForm.entertainmentAdj
  ir8aForm.exgratiaTotal = ir8aForm.exgratiaAmt + ir8aForm.exgratiaAdj
  ir8aForm.gratuityTotal = ir8aForm.gratuityAmt + ir8aForm.gratuityAdj
  ir8aForm.insuranceTotal = ir8aForm.insuranceAmt + ir8aForm.insuranceAdj
  ir8aForm.noticeTotal = ir8aForm.noticeAmt + ir8aForm.noticeAdj
  ir8aForm.otherTotal = ir8aForm.otherAmt + ir8aForm.otherAdj
  ir8aForm.otherLumpsumTotal = ir8aForm.otherLumpsumAmt + ir8aForm.otherLumpsumAdj
  ir8aForm.pensionTotal = ir8aForm.pensionAmt + ir8aForm.pensionAdj
  ir8aForm.retirementTotal = ir8aForm.retirementAmt + ir8aForm.retirementAdj
  ir8aForm.retirement2Total = ir8aForm.retirement2Amt + ir8aForm.retirement2Adj
  ir8aForm.transportTotal = ir8aForm.transportAmt + ir8aForm.transportAdj
  ir8aForm.cpfRadio = ir8aForm.cpfEm + ir8aForm.cpfEmAdj !== 0 && ir8aForm.pensionFundName === null ? 'cpf' : 'pfn'

  // readonly (calculation) fields
  ir8aForm.allowanceAmt = ir8aForm.transportTotal + ir8aForm.entertainmentTotal + ir8aForm.otherTotal

  return ir8aForm
}
