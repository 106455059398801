import { ThunkResult } from '~/types/store'
import { apiSynchronizeGoogleCalendar } from '../api/google-calendar.api'
import { showError, showSuccess } from '~/utils'
import { DateRange } from '~/types/common'
import { fetchGoogleCalendar } from './fetch-google-calendar'
import { dispatch } from '~/stores/store'

export const synchronizeGoogleCalendar = (id: string, dateRange: DateRange): ThunkResult<void> => {
  return async () => {
    if (!id) return

    const { status, errors, message, errorData } = await apiSynchronizeGoogleCalendar(id, dateRange)
    if (status) {
      dispatch(fetchGoogleCalendar(id))
      showSuccess('Synchronised')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { errors }
  }
}
