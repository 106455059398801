import { request, getBaseUrl } from '~/utils/request'
import { GoogleCalendarConnectionState, GoogleCalendarState, IGoogleCalendarSetting } from '../types'
import { Operation } from 'fast-json-patch'
import { DateRange } from '~/types/common'

const baseUrl = getBaseUrl('/leave/googlecalendar')

export const apiGetGoogleCalendarAuthUrl = (id?: string) => request<string>('get', `${baseUrl}/login/${id}`)

export const apiGetGoogleCalendarAuthToken = (authorizationCode: string, state: string) =>
  request<{ id: string }>('post', `${baseUrl}/token`, { authorizationCode, state })

export const apiGetGoogleCalendars = () => request<GoogleCalendarState[]>('get', baseUrl)

export const apiGetGoogleCalendar = (id: string) => request<GoogleCalendarState>('get', `${baseUrl}/${id}`)

export const apiUpdateGoogleCalendar = (id: string, googleCalendar: Operation[]) =>
  request<void>('patch', `${baseUrl}/${id}`, googleCalendar)

export const apiUpdateGoogleCalendarSetting = (id: string, setting: IGoogleCalendarSetting) =>
  request<GoogleCalendarState>('put', `${baseUrl}/${id}`, setting)

export const apiDeleteGoogleCalendar = (id: string) => request<void>('delete', `${baseUrl}/${id}`)

export const apiCheckGoogleCalendarConnection = (id: string) =>
  request<GoogleCalendarConnectionState>('get', `${baseUrl}/connection/${id}`)

export const apiDisconnectGoogleCalendar = (id: string) => request<void>('delete', `${baseUrl}/connection/${id}`)

export const apiSynchronizeGoogleCalendar = (id: string, params: DateRange) =>
  request<void>('get', `${baseUrl}/synchronize/${id}`, params)
