import React, { FC, useCallback, useState } from 'react'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import { Button, Card, ColumnsType, PageHeader, Space, Table, Tag, Tooltip } from '~/core-components'
import { DocumentTitle } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { GoogleCalendarSyncStatus, Permission, PermissionAction } from '~/constants'
import { SETTINGS_ROUTES } from '~/routes/routes'
import { GoogleCalendarState } from '../../types'
import { useGoogleCalendars } from '../../hooks'
import { initiateGoogleCalendarAuthentication } from '../../actions'
import { dispatch } from '~/stores/store'
import './GoogleCalendars.less'

type GoogleCalendarTable = GoogleCalendarState

const routes = [
  {
    path: SETTINGS_ROUTES.main,
    breadcrumbName: 'Settings'
  },
  {
    path: '',
    breadcrumbName: 'Overview'
  }
]

interface GoogleCalendarProps {}

export const GoogleCalendars: FC<GoogleCalendarProps> = () => {
  const [googleCalendars, loading] = useGoogleCalendars()
  const canModify = usePermissionGate(Permission.googleCalendar, PermissionAction.Modify)
  const [connecting, setConnecting] = useState(false)

  const getTag = useCallback((status: string) => {
    if (status === GoogleCalendarSyncStatus.Completed) {
      return <Tag type="success">successful</Tag>
    } else if (status === GoogleCalendarSyncStatus.Error) {
      return <Tag type="danger">failed</Tag>
    }
    return ''
  }, [])

  const columns: ColumnsType<GoogleCalendarTable> = [
    {
      title: 'Integration',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: 'Google account',
      key: 'account',
      dataIndex: 'account'
    },
    {
      title: 'Auto-sync',
      key: 'isAutoSync',
      dataIndex: 'isAutoSync',
      width: 90,
      align: 'center',
      render: (value: boolean) => (value ? <Tag type="success">On</Tag> : <Tag>Off</Tag>)
    },
    {
      title: 'Last sync',
      key: 'syncDate',
      dataIndex: 'syncDate',
      render: (value: string, record) => {
        const formattedDate = value ? moment(value).format('DD MMM YYYY hh:mm A') : undefined
        return value ? (
          record.status === GoogleCalendarSyncStatus.Error ? (
            <Tooltip title={`Last successful synchronisation on ${formattedDate}`}>{getTag(record.status)}</Tooltip>
          ) : (
            formattedDate
          )
        ) : undefined
      }
    },
    {
      key: 'action',
      align: 'right',
      width: 70,
      render: (value: string, record) =>
        canModify && <Link to={SETTINGS_ROUTES.googleCalendar.replace(':id', record.id)}>setting</Link>
    }
  ]

  const handleConnect = useCallback(async () => {
    try {
      setConnecting(true)
      await dispatch(initiateGoogleCalendarAuthentication())
    } finally {
      setConnecting(false)
    }
  }, [])

  return (
    <div id="google-calendars" className="google-calendars">
      <DocumentTitle title="Google Calendar Integration" />
      <PageHeader
        title={
          <Space>
            <img alt="Google Calendar" src="/partners/google-calendar.png" width="32" />
            Google Calendar integration
          </Space>
        }
        containerId="google-calendars"
        breadcrumb={{ routes }}
        extra={
          canModify && (
            <Button key="add" onClick={handleConnect} loading={connecting}>
              Add Google account
            </Button>
          )
        }
      />
      <div className="google-calendars__body">
        <Card table>
          <Table rowKey="id" dataSource={googleCalendars} pagination={false} columns={columns} loading={loading} />
        </Card>
      </div>
    </div>
  )
}
