import React, { FC, useCallback, useEffect, useState } from 'react'
import { Alert } from '~/core-components'
import { Col, EditableCard, EditableCardState, Row } from '~/components'
import { usePermissionGate } from '~/features/iam'
import { useCompaniesDict } from '~/features/master'
import { Permission, PermissionAction, emptyGuid } from '~/constants'
import { ActionResult, Errors } from '~/types/store'
import { dispatch } from '~/stores/store'
import { useXeroTenantMaps } from '../../hooks'
import { XeroTenantMapState } from '../../types'
import { updateXeroTenantMaps } from '../../actions'
import { XeroSelectTenant } from './XeroSelectTenant'
import './XeroTenantMap.less'

interface XeroTenantMapProps {}

interface FormData {
  tenantMaps: XeroTenantMapState[]
}

const EMPTY_FORM_DATA: FormData = {
  tenantMaps: []
}

export const XeroTenantMap: FC<XeroTenantMapProps> = () => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const canModify = usePermissionGate(Permission.xero, PermissionAction.Modify)
  const readOnly = cardState !== 'editing'
  const [errors, setErrors] = useState<Errors>()
  const [tenantMaps, tenantMapsLoading] = useXeroTenantMaps()
  const [companies, companiesLoading] = useCompaniesDict()
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const loading = tenantMapsLoading || companiesLoading

  const resetForm = useCallback(() => {
    if (companies) {
      setFormData({
        tenantMaps: Object.values(companies).map(c => {
          const existingMap = tenantMaps.find(t => t.companyId === c!.id)
          return {
            id: existingMap?.id || emptyGuid,
            companyId: c!.id,
            tenantId: existingMap?.tenantId
          } as XeroTenantMapState
        })
      })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [tenantMaps, companies])

  useEffect(() => {
    resetForm()
  }, [resetForm])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleEdit = useCallback(() => {
    setCardState('editing')
  }, [])

  const handleSave = useCallback(async () => {
    setCardState('saving')
    setErrors(undefined)

    let result: ActionResult | undefined
    try {
      result = await dispatch(updateXeroTenantMaps(formData.tenantMaps))
    } catch {
      setCardState('editing')
    }

    if (result?.errors) {
      setCardState('editing')
      setErrors(result.errors)
    }

    if (!result?.errors) {
      setCardState(undefined)
    }
  }, [formData])

  const handleCancel = useCallback(() => {
    setCardState(undefined)
    setErrors(undefined)

    resetForm()
  }, [resetForm])

  return (
    <EditableCard
      className="xero-tenant-map"
      title="Company mapping"
      bodyStyle={{ paddingBottom: !readOnly ? 6 : 24, paddingTop: 6 }}
      state={canModify ? cardState : 'readonly'}
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
      loading={loading}
    >
      <Row>
        <Col span={24}>
          {errors && Object.keys(errors).length > 0 && (
            <Alert
              className="xero-tenant-map__error"
              type="error"
              message={
                <>
                  {Object.keys(errors).map((error, index) => (
                    <div key={index}>{errors[error]}</div>
                  ))}
                </>
              }
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Row gutter={30}>
            <Col flex="250px">
              <div className="xero-tenant-map__row-title">Company</div>
            </Col>
            <Col flex="50px"></Col>
            <Col flex="1">
              <div className="xero-tenant-map__row-title">Xero Organisation</div>
            </Col>
          </Row>
          {formData.tenantMaps.map((t, index) => (
            <Row gutter={30} key={index} align="middle" className="xero-tenant-map__row">
              <Col flex="250px">{companies[t.companyId]?.name}</Col>
              <Col flex="50px">
                <i className="fal fa-arrow-right-long" />
              </Col>
              <Col flex="1">
                <XeroSelectTenant
                  value={t.tenantId}
                  readOnly={readOnly}
                  onChange={(value: string) =>
                    handleFormDataChange({
                      tenantMaps: [
                        ...formData.tenantMaps.slice(0, index),
                        { ...formData.tenantMaps[index], tenantId: value },
                        ...formData.tenantMaps.slice(index + 1, formData.tenantMaps.length)
                      ]
                    })
                  }
                />
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </EditableCard>
  )
}
