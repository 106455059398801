import React, { FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { Form } from '~/core-components'
import { Col, DrawerForm, YearKeyValues, Row, MonthKeyValues, EmSelect } from '~/components'
import { apiGetEmSelect } from '~/features/employee'
import { showSuccess } from '~/utils'
import { useFocus } from '~/hooks/use-focus'
import { apiProcessYtdRecords } from '../../api/ytd-record.api'
import { dispatch } from '~/stores/store'
import { refetchYtdRecordView } from '../../reducers'

export interface ProcessYtdRecordsDrawerProps {
  visible: boolean
  onClose: () => void
}

interface FormData {
  ytdYear: string
  paymentMonth: number | undefined
  employeeId: string
}

const THIS_YEAR = moment().year()

export const EMPTY_FORM_DATA: FormData = {
  ytdYear: THIS_YEAR.toString(),
  paymentMonth: undefined,
  employeeId: ''
}

export const ProcessYtdRecordsDrawer: FC<ProcessYtdRecordsDrawerProps> = ({ visible, onClose }) => {
  const [focusRef, setFocus] = useFocus(true)
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
  }, [visible, setFocus])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    setLoading(true)
    try {
      await apiProcessYtdRecords(
        formData.ytdYear,
        formData.paymentMonth || 0,
        formData.employeeId ? [formData.employeeId] : null
      )
      typeof onClose === 'function' && onClose()
      showSuccess('Recalculated')
      dispatch(refetchYtdRecordView())
    } finally {
      setLoading(false)
    }
  }, [formData, onClose])

  const handleCloseDrawer = useCallback(() => {
    typeof onClose === 'function' && onClose()
  }, [onClose])

  const handleFetchEmployees = useCallback(async () => {
    const { status, result } = await apiGetEmSelect('active')
    if (status) {
      return result
    }
    return []
  }, [])

  return (
    <DrawerForm
      open={visible}
      title="Recalculate YTD records"
      okText="Recalculate"
      onClose={handleCloseDrawer}
      confirmLoading={loading}
      width={500}
      className="process-ytd-records-drawer"
      formId="form-process-ytd-records"
    >
      <Form id="form-process-ytd-records" onFinish={handleOk}>
        <Row>
          <Col span={24}>
            <Form.Item label="Year">
              <YearKeyValues
                ref={focusRef}
                value={formData.ytdYear}
                startYear={THIS_YEAR}
                noOfYearBefore={-10}
                noOfYearAfter={1}
                allowClear={false}
                onChange={(ytdYear: string) => handleFormDataChange({ ytdYear })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Month">
              <MonthKeyValues
                value={formData.paymentMonth}
                onChange={(paymentMonth: string) => handleFormDataChange({ paymentMonth })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Employee">
              <EmSelect
                allowClear
                value={formData.employeeId}
                onFetch={handleFetchEmployees}
                onChange={(employeeId: string) => handleFormDataChange({ employeeId })}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DrawerForm>
  )
}
