import { EntityState } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { IrasErrorState } from './iras.state'
import { Ir8aFormState } from './ir8a.state'
import { A8aFormState } from './a8a.state'
import { Ir8sFormState } from './ir8s.state'
import { A8bFormState } from './a8b.state'
import { Ir21FormState } from './ir21.state'
import { EmSelectState } from '~/components'
import {
  YtdSubmissionIr8aViewState,
  YtdSubmissionIr8sViewState,
  YtdSubmissionA8aViewState,
  YtdSubmissionA8bViewState,
  YtdSubmissionState,
  YtdSubmissionViewState,
  YtdSummaryState,
  IrasLogState
} from './ytd-submission.state'
import {
  Ir21ResigneeState,
  Ir21SubmissionA1ViewState,
  Ir21SubmissionIr21ViewState,
  Ir21SubmissionState,
  Ir21SubmissionViewState
} from './ir21-submission.state'
import { YtdCompanyState } from './ytd-company.state'
import { Ir21A1FormState } from './ir21-a1.state'
import { YtdIndexViewState } from './ytd-index.state'
import { YtdRecordViewState } from './ytd-record.state'

export const taxPersistConfig = {
  key: 'tax',
  whitelist: [
    'irasState',
    'irasStateTimestamp',
    'irasSubmissionId',
    'ir8aForm',
    'ir8aFormRefetch',
    'ir8aErrors',
    'ir8aWarnings',
    'ir8sForm',
    'ir8sFormRefetch',
    'ir8sErrors',
    'ir8sWarnings',
    'a8aForm',
    'a8aFormRefetch',
    'a8aErrors',
    'a8aWarnings',
    'a8bForm',
    'a8bFormRefetch',
    'a8bErrors',
    'a8bWarnings',
    'ytdSubmissionExpanded',
    'ytdSubmissionViews',
    'ytdSubmissionViewsRefetch',
    'ytdSubmissions',
    'ytdSummaries',
    'ytdSubmissionIr8aViews',
    'ytdSubmissionIr8aViewsRefetch',
    'ytdSubmissionIr8sViews',
    'ytdSubmissionIr8sViewsRefetch',
    'ytdSubmissionA8aViews',
    'ytdSubmissionA8aViewsRefetch',
    'ytdSubmissionA8bViews',
    'ytdSubmissionA8bViewsRefetch',
    'ytdSubmissionEmployees',
    'ytdCompanies',
    'ytdIndexView',
    'ytdIndexViewRefetch',
    'ytdRecordView',
    'ir21Form',
    'ir21FormRefetch',
    'ir21Submissions',
    'ir21SubmissionViews',
    'ir21SubmissionViewsRefetch',
    'ir21SubmissionIr21Views',
    'ir21SubmissionIr21ViewsRefetch',
    'ir21SubmissionEmployees',
    'ir21A1Form',
    'ir21A1FormRefetch'
  ],
  storage
}

export interface TaxRootState {
  irasState: string
  irasStateTimestamp: number
  irasSubmissionId: string

  // IR8A
  ir8aForm: EntityState<Ir8aFormState>
  ir8aFormLoading: boolean
  ir8aFormRefetch: number
  ir8aFormUpdating: boolean
  ir8aFormDeleting: boolean
  ir8aErrors: EntityState<IrasErrorState>
  ir8aWarnings: EntityState<IrasErrorState>
  ir8aProcessing: boolean

  // IR8S
  ir8sForm: EntityState<Ir8sFormState>
  ir8sFormLoading: boolean
  ir8sFormRefetch: number
  ir8sFormUpdating: boolean
  ir8sFormDeleting: boolean
  ir8sErrors: EntityState<IrasErrorState>
  ir8sWarnings: EntityState<IrasErrorState>
  ir8sProcessing: boolean

  // Appendix 8A
  a8aForm: EntityState<A8aFormState>
  a8aFormLoading: boolean
  a8aFormRefetch: number
  a8aFormUpdating: boolean
  a8aFormDeleting: boolean
  a8aErrors: EntityState<IrasErrorState>
  a8aWarnings: EntityState<IrasErrorState>
  a8aProcessing: boolean

  // Appendix 8B
  a8bForm: EntityState<A8bFormState>
  a8bFormLoading: boolean
  a8bFormRefetch: number
  a8bFormUpdating: boolean
  a8bFormDeleting: boolean
  a8bErrors: EntityState<IrasErrorState>
  a8bWarnings: EntityState<IrasErrorState>

  ytdSubmissionExpanded: boolean
  ytdSubmissionViews: EntityState<YtdSubmissionViewState>
  ytdSubmissionViewsLoading: boolean
  ytdSubmissionViewsRefetch: number
  ytdSubmissions: EntityState<YtdSubmissionState>
  ytdSubmissionsLoading: boolean
  ytdSummaries: EntityState<YtdSummaryState>
  ytdSummariesLoading: boolean
  ytdSubmissionIr8aViews: EntityState<YtdSubmissionIr8aViewState>
  ytdSubmissionIr8aViewsLoading: boolean
  ytdSubmissionIr8aViewsRefetch: number
  ytdSubmissionIr8sViews: EntityState<YtdSubmissionIr8sViewState>
  ytdSubmissionIr8sViewsRefetch: number
  ytdSubmissionIr8sViewsLoading: boolean
  ytdSubmissionA8aViews: EntityState<YtdSubmissionA8aViewState>
  ytdSubmissionA8aViewsLoading: boolean
  ytdSubmissionA8aViewsRefetch: number
  ytdSubmissionA8bViews: EntityState<YtdSubmissionA8bViewState>
  ytdSubmissionA8bViewsLoading: boolean
  ytdSubmissionA8bViewsRefetch: number
  ytdSubmissionEmployees: { [ytdFormType: string]: EntityState<EmSelectState> }
  ytdSubmissionEmployeesLoading: { [ytdFormType: string]: boolean }
  ytdCompanies: EntityState<YtdCompanyState>
  ytdCompaniesLoading: boolean
  ytdIndexView: EntityState<YtdIndexViewState>
  ytdIndexViewLoading: boolean
  ytdIndexViewRefetch: number
  ytdIndexYears: string[]
  ytdIndexYearsLoading: boolean

  // Ytd record
  ytdRecordView: EntityState<YtdRecordViewState>
  ytdRecordViewLoading: boolean
  ytdRecordViewRefetch: number
  ytdRecordExpanded: boolean

  irasLogs: EntityState<IrasLogState>
  irasLogsLoading: boolean

  // IR21 Submissions
  ir21Submissions: EntityState<Ir21SubmissionState>
  ir21SubmissionsLoading: boolean
  ir21SubmissionViews: EntityState<Ir21SubmissionViewState>
  ir21SubmissionViewsLoading: boolean
  ir21SubmissionViewsRefetch: number
  ir21SubmissionIr21Views: EntityState<Ir21SubmissionIr21ViewState>
  ir21SubmissionIr21ViewsLoading: boolean
  ir21SubmissionIr21ViewsRefetch: number
  ir21SubmissionA1Views: EntityState<Ir21SubmissionA1ViewState>
  ir21SubmissionA1ViewsLoading: boolean
  ir21SubmissionA1ViewsRefetch: number
  ir21SubmissionEmployees: { [ir21FormType: string]: EntityState<EmSelectState> }
  ir21SubmissionEmployeesLoading: { [ir21FormType: string]: boolean }
  ir21Resignees: EntityState<Ir21ResigneeState>
  ir21ResigneesLoading: boolean

  // IR21 Form
  ir21SubmissionExpanded: boolean
  ir21Form: EntityState<Ir21FormState>
  ir21FormLoading: boolean
  ir21FormRefetch: number
  ir21FormUpdating: boolean
  ir21FormDeleting: boolean
  ir21Processing: boolean

  // IR21A1 Form
  ir21A1Form: EntityState<Ir21A1FormState>
  ir21A1FormLoading: boolean
  ir21A1FormRefetch: number
  ir21A1FormUpdating: boolean
  ir21A1FormDeleting: boolean
  ir21A1Processing: boolean
}
