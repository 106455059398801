import { showError } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiEnable2fa } from '../api/two-factor.api'

export const enable2fa = (): ThunkResult<void> => {
  return async (): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiEnable2fa()
    if (!status) {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
