import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { TAX_ROUTES } from '~/routes/routes'
import { LoadingOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import { InfoTooltip, Person, SearchInput } from '~/components'
import { Button, Card, ColumnType, ColumnsType, Dropdown, Form, Link, Space, Table, Tooltip } from '~/core-components'
import {
  Screen,
  updateViewCriteria,
  useFirstView,
  useViewSchema,
  ViewCriteria,
  ViewCriteriaSimple,
  ViewSelectionButton
} from '~/features/selection'
import { EmSelectionDrawer } from '~/features/employee'
import { usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction, YtdSubmissionStatus } from '~/constants'
import { dispatch } from '~/stores/store'
import { StoreState } from '~/types/store'
import { Pagination } from '~/types/common'
import { formatYearMonth, formatMonth, formatMoney, getBaseUrl } from '~/utils'
import { refetchYtdSubmissionIr8aViews, selectYtdSubmissionIr8aViewById } from '../../../reducers'
import {
  addIr8aDraft,
  fetchIr8aEmSelections,
  fetchIr8aNewEmSelections,
  fetchYtdSubmissionIr8aView,
  lockAllIr8aDrafts,
  lockIr8aDraft,
  processIr8a,
  unlockAllIr8aDrafts,
  unlockIr8aDraft
} from '../../../actions'
import { YtdSubmissionIr8aState, YtdSubmissionState } from '../../../types'
import { ReconciliationReportButton } from '../../Iras/ReconciliationReportButton'
import './Ir8aRecords.less'

interface Ir8aRecordsProps {
  ytdSubmission?: YtdSubmissionState
}

interface EmSelectionDrawerState {
  visible: boolean
}

const SCREEN_CODE: Screen = 'ytd_ir8a'
const PAGE_SIZE_OPTIONS = ['20', '50', '100']
const EM_SELECTION_DRAWER_STATE: EmSelectionDrawerState = { visible: false }

const nameColWidth = 250
const amountColWidth = 100
const defaultColWidth = 150
const paginationStyle: CSSProperties = { marginRight: 20 }
const baseUrl = getBaseUrl('/filestore')

export const Ir8aRecords: FC<Ir8aRecordsProps> = ({ ytdSubmission }) => {
  const ytdSubmissionId = ytdSubmission?.id || ''
  const selection = useSelector((state: StoreState) => state.selection.sysSelectionFields[SCREEN_CODE])
  const [view, viewLoading] = useFirstView(SCREEN_CODE)
  const viewId = view?.id || ''
  const [schema] = useViewSchema(SCREEN_CODE, viewId)
  const dataLoading = useSelector((state: StoreState) => state.tax.ytdSubmissionIr8aViewsLoading)
  const data = useSelector((state: StoreState) => selectYtdSubmissionIr8aViewById(state, ytdSubmissionId))

  const [locking, setLocking] = useState<string>()
  const [lockingAll, setLockingAll] = useState(false)
  const [unlockingAll, setUnlockingAll] = useState(false)
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [search, setSearch] = useState<string>('')
  const refetch = useSelector((state: StoreState) => state.tax.ytdSubmissionIr8aViewsRefetch)
  const canModify = usePermissionGate(Permission.ytd, PermissionAction.Modify)
  const expanded = useSelector((state: StoreState) => state.tax.ytdSubmissionExpanded)
  const processing = useSelector((state: StoreState) => state.tax.ir8aProcessing)
  const [adding, setAdding] = useState(false)

  const [recalculateDrawerState, setRecalculateDrawerState] =
    useState<EmSelectionDrawerState>(EM_SELECTION_DRAWER_STATE)
  const [addEmployeeDrawerState, setAddEmployeeDrawerState] =
    useState<EmSelectionDrawerState>(EM_SELECTION_DRAWER_STATE)

  useEffect(() => {
    if (viewId && ytdSubmissionId && !processing) {
      dispatch(
        fetchYtdSubmissionIr8aView(ytdSubmissionId, viewId, { offset: pageSize * (page - 1), limit: pageSize }, search)
      )
    }
  }, [viewId, ytdSubmissionId, page, pageSize, search, processing, refetch])

  const tableWidth =
    (schema?.selection.reduce(
      (sum, f) => (sum += selection?.entities[f.selectionFieldId]?.width || defaultColWidth),
      0
    ) || 0) +
    nameColWidth +
    7 * amountColWidth

  const columns: ColumnsType<YtdSubmissionIr8aState> = [
    {
      title: 'Name',
      key: 'employeeTaxNo',
      dataIndex: 'employeeTaxNo',
      fixed: 'left',
      className: 'first-col',
      width: nameColWidth,
      render: (value: string, record) => (
        <Person
          name={record.employeeName}
          description={!expanded && value}
          photo={record.photoId && `${baseUrl}/file/${record.photoId}/thumbnailphoto/${expanded ? 18 : 36}`}
          // photo={
          //   record.employeeId &&
          //   `/employee/employee/${record.employeeId}/avatar/${expanded ? 18 : 36}?photo_id=${record.photoId}`
          // }
          openAsNewTab={false}
          path={TAX_ROUTES.formsSubmission
            .replace(':id', ytdSubmissionId)
            .replace(':employeeTaxNo', value)
            .replace(':tab?', 'ir8a')}
          size={expanded ? 18 : 36}
        />
      )
    }
  ]

  if (canModify && ytdSubmission?.status === YtdSubmissionStatus.draft) {
    columns.push({
      key: 'action',
      width: 24,
      className: 'action-cell',
      render: (value: string, record: YtdSubmissionIr8aState) => (
        <Tooltip title={!!record.lockedBy ? 'Locked' : 'Unlocked'}>
          <Link
            className={classNames('ir8arecords__lock', { 'ir8arecords__lock--locked': !!record.lockedBy })}
            onClick={() => handleLock(record)}
          >
            {locking === record.id ? (
              <LoadingOutlined />
            ) : !!record.lockedBy ? (
              <i className="fal fa-lock" />
            ) : (
              <i className="fal fa-lock-open" />
            )}
          </Link>
        </Tooltip>
      )
    })
  }

  if (schema) {
    // Configurable employee columns
    columns.push(
      ...schema?.selection.map(f => {
        var field = selection?.entities[f.selectionFieldId]
        return {
          title: field?.description,
          key: field?.fieldName,
          dataIndex: field?.fieldName,
          width: field?.width || defaultColWidth,
          render: (value: string | number, record: YtdSubmissionIr8aState, index: number) => {
            if (field?.format === 'date' && value) {
              return moment(value).format('DD MMM YYYY')
            }
            if (field?.format === 'yearmonth' && value) {
              return formatYearMonth(value as string)
            }
            if (field?.format === 'month' && value) {
              return formatMonth(value as number)
            }
            if (field?.format === 'money' && value) {
              return formatMoney(value as number)
            }
            return value
          }
        }
      })
    )
  }

  columns.push(
    ...[
      {
        title: 'Salary',
        key: 'grossAmt',
        dataIndex: 'grossAmt',
        width: amountColWidth,
        align: 'right',
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<YtdSubmissionIr8aState>,
      {
        title: 'Bonus',
        key: 'bonusAmt',
        dataIndex: 'bonusAmt',
        width: amountColWidth,
        align: 'right',
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<YtdSubmissionIr8aState>,
      {
        title: "Director's fee",
        key: 'directorFeeAmt',
        dataIndex: 'directorFeeAmt',
        width: amountColWidth,
        align: 'right',
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<YtdSubmissionIr8aState>,
      {
        title: 'Others',
        key: 'totalOtherAmt',
        dataIndex: 'totalOtherAmt',
        width: amountColWidth,
        align: 'right',
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<YtdSubmissionIr8aState>,
      {
        title: (
          <>
            CPF
            <InfoTooltip title="CPF/designated pension or provident fund" />
          </>
        ),
        key: 'cpfEm',
        dataIndex: 'cpfEm',
        width: amountColWidth,
        align: 'right',
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<YtdSubmissionIr8aState>,
      {
        title: 'Donation',
        key: 'totalDonationAmt',
        dataIndex: 'totalDonationAmt',
        width: amountColWidth,
        align: 'right',
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<YtdSubmissionIr8aState>,
      {
        title: 'MBF',
        key: 'fundMbf',
        dataIndex: 'fundMbf',
        width: amountColWidth,
        align: 'right',
        render: (value: number) => formatMoney(value, 2)
      } as ColumnType<YtdSubmissionIr8aState>
    ]
  )

  const handleLock = useCallback(async record => {
    if (record) {
      setLocking(record.id)
      try {
        if (record.lockedBy) {
          await dispatch(unlockIr8aDraft(record.id))
        } else {
          await dispatch(lockIr8aDraft(record.id))
        }
      } finally {
        setLocking(undefined)
      }
    }
  }, [])

  const handleLockAll = useCallback(async () => {
    if (ytdSubmissionId) {
      setLockingAll(true)
      try {
        await dispatch(lockAllIr8aDrafts(ytdSubmissionId))
      } finally {
        setLockingAll(false)
      }
    }
  }, [ytdSubmissionId])

  const handleUnlockAll = useCallback(async () => {
    if (ytdSubmissionId) {
      setUnlockingAll(true)
      try {
        await dispatch(unlockAllIr8aDrafts(ytdSubmissionId))
      } finally {
        setUnlockingAll(false)
      }
    }
  }, [ytdSubmissionId])

  const handleCloseSelectionDrawer = useCallback((changed?: boolean) => {
    if (changed) dispatch(refetchYtdSubmissionIr8aViews())
  }, [])

  const handleCriteriaApply = useCallback(
    async (criteria: ViewCriteria[]) => {
      if (viewId) {
        setPage(1)
        await dispatch(updateViewCriteria(SCREEN_CODE, viewId, { id: viewId, criteria }))
        dispatch(refetchYtdSubmissionIr8aViews())
      }
    },
    [viewId]
  )

  const handlePaginationChange = useCallback((page: number, pageSize?: number) => {
    setPage(page)
    setPageSize(pageSize || 20)
  }, [])

  const handleSearch = useCallback((value: string) => {
    setPage(1)
    setSearch(value)
  }, [])

  const handleCalculateAll = useCallback(() => {
    dispatch(processIr8a(ytdSubmissionId))
  }, [ytdSubmissionId])

  const handleCalculateSome = useCallback(() => {
    setRecalculateDrawerState({ visible: true })
  }, [])

  const handleFetchDataRecalculate = useCallback(
    (viewId: string, pagination: Pagination, search: string) => {
      if (ytdSubmissionId) {
        dispatch(fetchIr8aEmSelections(ytdSubmissionId, viewId, pagination, search))
      }
    },
    [ytdSubmissionId]
  )

  const handleSelectRecalculate = useCallback(
    async (employeeTaxNos: string[]) => {
      await dispatch(processIr8a(ytdSubmissionId, employeeTaxNos))
    },
    [ytdSubmissionId]
  )

  const handleCloseRecalculateDrawer = useCallback(() => {
    setRecalculateDrawerState(EM_SELECTION_DRAWER_STATE)
  }, [])

  const handleAddEmployee = useCallback(() => {
    setAddEmployeeDrawerState({ visible: true })
  }, [])

  const handleFetchDataAddEmployee = useCallback(
    (viewId: string, pagination: Pagination, search: string) => {
      if (ytdSubmissionId) {
        dispatch(fetchIr8aNewEmSelections(ytdSubmissionId, viewId, pagination, search))
      }
    },
    [ytdSubmissionId]
  )

  const handleSelectAddEmployee = useCallback(
    async (employeeTaxNos: string[]) => {
      try {
        setAdding(true)
        await dispatch(addIr8aDraft({ ytdSubmissionId, employeeTaxNos }))
        dispatch(refetchYtdSubmissionIr8aViews())
      } finally {
        setAdding(false)
      }
    },
    [ytdSubmissionId]
  )

  const handleCloseAddEmployeeDrawer = useCallback(() => {
    setAddEmployeeDrawerState(EM_SELECTION_DRAWER_STATE)
  }, [])

  return (
    <div className="ir8a-records">
      <div className="ir8a-records__action-bar">
        <Form.Item label="">
          <SearchInput onSearch={handleSearch} />
        </Form.Item>
        <ViewCriteriaSimple screenCode={SCREEN_CODE} viewId={viewId} onApply={handleCriteriaApply} label="" />
        <Space align="start">
          {canModify && ytdSubmission?.status === YtdSubmissionStatus.draft && (
            <>
              <Dropdown
                disabled={lockingAll || unlockingAll}
                menu={{
                  items: [
                    { key: 'lock', label: 'Lock all employees', onClick: handleLockAll },
                    { key: 'unlock', label: 'Unlock all employees', onClick: handleUnlockAll }
                  ]
                }}
              >
                <Button
                  className="ir8arecords__action-bar-buttons-lock"
                  hidden={ytdSubmission?.status !== YtdSubmissionStatus.draft}
                >
                  {lockingAll || unlockingAll ? (
                    <LoadingOutlined />
                  ) : (
                    <Space size={4}>
                      <i className="fal fa-lock" />
                      <i className="fa-light fa-angle-down" />
                    </Space>
                  )}
                </Button>
              </Dropdown>
              <Button onClick={handleAddEmployee}>Add employee</Button>
            </>
          )}
          <ViewSelectionButton
            screenCode={SCREEN_CODE}
            viewId={viewId}
            title="Configure IR8A record columns"
            onClose={handleCloseSelectionDrawer}
          />
        </Space>
      </div>
      <Card fitParent table>
        <Table
          rowKey="id"
          dataSource={data?.data}
          columns={columns}
          fitParent
          loading={dataLoading || viewLoading}
          scroll={{ x: tableWidth, y: 1000 }}
          pagination={{
            total: data?.count,
            current: page,
            pageSize,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            showSizeChanger: true,
            onChange: handlePaginationChange,
            style: paginationStyle
          }}
        />
      </Card>

      <div className="ir8a-records__footer-bar">
        <Space className="ir8a-records__footer-bar--flex">
          <ReconciliationReportButton
            ytdYear={ytdSubmission?.ytdYear || ''}
            ytdSubmissionId={ytdSubmission?.id || ''}
            employerTaxNo={ytdSubmission?.employerTaxNo || ''}
          >
            IRAS reconciliation report
          </ReconciliationReportButton>
        </Space>
        {canModify && ytdSubmission?.status === YtdSubmissionStatus.draft && (
          <Space>
            <Dropdown.Button
              menu={{
                items: [{ key: 'selected', label: 'Recalculate selected employees...', onClick: handleCalculateSome }]
              }}
              icon={<i className="fa-light fa-angle-down" />}
              onClick={handleCalculateAll}
              buttonsRender={([leftButton, rightButton]) => [
                React.cloneElement(leftButton as React.ReactElement, { loading: processing || adding }),
                rightButton
              ]}
            >
              Recalculate all
            </Dropdown.Button>
          </Space>
        )}
      </div>
      <EmSelectionDrawer
        {...recalculateDrawerState}
        viewName="ir8a_recalculate"
        title="Select the employees to recalculate IR8A"
        okText="Recalculate"
        onFetchData={handleFetchDataRecalculate}
        onSelect={handleSelectRecalculate}
        onClose={handleCloseRecalculateDrawer}
        resetOnClose={true}
      />
      <EmSelectionDrawer
        {...addEmployeeDrawerState}
        viewName="ir8a_newem"
        title="Add employees"
        okText="Add"
        onFetchData={handleFetchDataAddEmployee}
        onSelect={handleSelectAddEmployee}
        onClose={handleCloseAddEmployeeDrawer}
        resetOnClose={true}
      />
    </div>
  )
}
