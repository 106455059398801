import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment-timezone'
import confirm from 'antd/lib/modal/confirm'
import pick from 'lodash/pick'
import { Alert, ColumnsType, Form, Input, SecondaryText, Select, Space, Switch, Table } from '~/core-components'
import { Col, DayIndicator, DrawerForm, EmSelect, InactiveTag, Row, TimeDayInput } from '~/components'
import { apiGetEmSelect, EmPublicPerson } from '~/features/employee'
import { usePermissionGate } from '~/features/iam'
import { dispatch } from '~/stores/store'
import { useFocus } from '~/hooks/use-focus'
import { ActionResult, Errors } from '~/types/store'
import { formatDate, isInactive } from '~/utils'
import { addTimeLog, updateTimeLog, deleteTimeLog } from '../../../actions'
import {
  TimeLogRowState,
  ISaveTimeLog,
  ProjectByEmployeeState,
  LocationState,
  TimeLogRequestData
} from '../../../types'
import { apiGetProjectsByEmployee } from '~/features/attendance/api/project.api'
import {
  useLocations,
  useLocationsDict,
  useProjectsDict,
  useShiftsDict,
  useTimeLogApprovalHistories
} from '~/features/attendance/hooks'
import { LocationKeyValues } from '../../Locations/components/LocationKeyValues'
import { ShiftKeyValues } from '../../Shift/components/ShiftKeyValues'
import { ShiftDayTime } from '../../Shifts/components/ShiftDayTime'
import { Permission, PermissionAction, TimeLogStatus } from '~/constants'
import { TimeLogCurrentApprover } from '../../TimeLogCurrentApprover/TimeLogCurrentApprover'
import { TimeLogApprovalHistories } from '../../TimeLogApprovalHistories/TimeLogApprovalHistories'
import './MutateTimeLogDrawer.less'

export interface MutateTimeLogDrawerProps {
  visible: boolean
  data?: TimeLogRowState
  editing?: boolean
  onClose: () => void
}

type FormData = ISaveTimeLog

const EMPTY_FORM_DATA: FormData = {
  employeeId: '',
  timeIn: '',
  timeOut: '',
  locationId: '',
  outLocationId: '',
  projectId: '',
  shiftId: '',
  notes: '',
  timeInRequest: '',
  timeOutRequest: ''
}

const TODAY = moment().format('YYYY-MM-DD')

export const MutateTimeLogDrawer: FC<MutateTimeLogDrawerProps> = ({
  visible,
  data,
  editing,
  onClose
}: MutateTimeLogDrawerProps) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [focusRef, setFocus] = useFocus(true)
  const [isEditing, setIsEditing] = useState(false)
  const [errors, setErrors] = useState<Errors>()
  const isOutLocation = formData.outLocationId && formData.outLocationId !== formData.locationId
  const [autoShift, setAutoShift] = useState(true)
  const [locations] = useLocations()
  const [projects, setProjects] = useState<ProjectByEmployeeState[]>([])
  const [projectsFetching, setProjectsFetching] = useState(false)
  const [approvalHistories] = useTimeLogApprovalHistories(data?.id)
  const canModify = usePermissionGate(Permission.attTimeLog, PermissionAction.Modify)
  const isNew = !data

  const [locationDict] = useLocationsDict()
  const [projectDict] = useProjectsDict()
  const [shiftDict] = useShiftsDict()

  useEffect(() => {
    if (visible) {
      setIsEditing(!!editing)
    }
  }, [visible, editing])

  const selectedLocation = useMemo(() => {
    let locationId = formData.locationId
    if (isOutLocation) {
      locationId = formData.outLocationId || ''
    }

    return locations.find(l => l.id === locationId)
  }, [formData.locationId, formData.outLocationId, isOutLocation, locations])

  const selectedLocationProjectCount = selectedLocation?.projectIds?.length || 0

  const getProjectsByLocation = useCallback(
    (location: LocationState | undefined, projects: ProjectByEmployeeState[] | undefined) => {
      if (location?.projectIds == null || location?.projectIds?.length === 0) {
        return projects || []
      } else {
        return projects?.filter(p => p.locationIds?.includes(location?.id || '')) || []
      }
    },
    []
  )

  const projectsByLocation = useMemo(() => {
    return getProjectsByLocation(selectedLocation, projects)
  }, [selectedLocation, getProjectsByLocation, projects])

  const handleFetchProjects = useCallback(
    async (employeeId: string) => {
      try {
        setProjectsFetching(true)
        const { status, result } = await apiGetProjectsByEmployee(employeeId)

        if (status) {
          const filteredResult = result.filter(a => !isInactive(a?.inactiveDate) || a?.id === formData.projectId)
          setProjects(filteredResult)
        }
      } finally {
        setProjectsFetching(false)
      }
    },
    [formData.projectId]
  )

  useEffect(() => {
    if (formData.employeeId) {
      handleFetchProjects(formData.employeeId)
    }
  }, [formData.employeeId, handleFetchProjects])

  useEffect(() => {
    setTimeout(() => visible && setFocus(), 100)
    setErrors(undefined)
  }, [visible, setFocus])

  useEffect(() => {
    if (data) {
      const {
        id,
        employeeId,
        timeIn,
        timeOut,
        locationId,
        outLocationId,
        projectId,
        shiftId,
        notes,
        timeInRequest,
        timeOutRequest
      } = data
      setFormData({
        id,
        employeeId,
        timeIn,
        timeOut,
        locationId,
        outLocationId,
        projectId,
        shiftId,
        notes,
        timeInRequest,
        timeOutRequest
      })
      setAutoShift(!shiftId)
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(formData => ({ ...formData, ...updates }))
  }, [])

  const handleOk = useCallback(async () => {
    let result: ActionResult | undefined
    setLoading(true)
    try {
      if (data) {
        result = await dispatch(updateTimeLog(data.id, mapToRequest(data), formData))
      } else {
        result = await dispatch(addTimeLog(formData))
      }
    } finally {
      setLoading(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      typeof onClose === 'function' && onClose()
      setFormData(EMPTY_FORM_DATA)
    }
  }, [data, formData, onClose])

  const handleDelete = useCallback(
    (timeLog: TimeLogRowState | undefined) => {
      if (timeLog) {
        const { id, timeIn } = timeLog
        confirm({
          title: 'Delete time log',
          content: `Do you want to delete time log dated "${formatDate(timeIn)}"?`,
          onOk: async () => {
            const result: ActionResult | undefined = await dispatch(deleteTimeLog(id))
            if (result?.errors) {
              setErrors(result.errors)
            }

            if (!result?.errors) {
              typeof onClose === 'function' && onClose()
            }
          },
          okText: 'Delete',
          okType: 'danger'
        })
      }
    },
    [onClose]
  )

  const handleFetchEmployees = useCallback(async () => {
    const { status, result } = await apiGetEmSelect('past3mth')
    if (status) {
      return result
    }
    return []
  }, [])

  const handleAutoShiftChange = useCallback(
    (autoShift: boolean) => {
      if (autoShift) handleFormDataChange({ shiftId: null })
      setAutoShift(autoShift)
    },
    [handleFormDataChange]
  )

  const handleToggleEdit = useCallback(() => {
    setIsEditing(isEditing => !isEditing)
  }, [])

  const isPending = !!formData.timeInRequest && !!formData.timeOutRequest
  const isOngoing = !!formData.timeInRequest && !formData.timeOutRequest

  const requestData: TimeLogRequestData<ISaveTimeLog>[] = useMemo(
    () => [
      {
        field: 'timeInRequest',
        label: 'Time in',
        current: formData.timeIn,
        request: formData.timeInRequest
      },
      {
        field: 'timeOutRequest',
        label: 'Time out',
        current: formData.timeOut,
        request: formData.timeOutRequest,
        currentTimeIn: formData.timeIn,
        requestTimeIn: formData.timeInRequest
      }
    ],
    [formData]
  )

  const columns: ColumnsType<TimeLogRequestData<ISaveTimeLog>> = useMemo(
    () => [
      {
        key: 'label',
        dataIndex: 'label',
        width: 150
      },
      {
        title: 'Current',
        key: 'current',
        dataIndex: 'current',
        render: (value: string, record: TimeLogRequestData<ISaveTimeLog>) =>
          value ? (
            record.field === 'timeOutRequest' ? (
              <TimeDayInput
                time={moment(value)}
                day={
                  record.currentTimeIn ? (moment(value).diff(moment(record.currentTimeIn), 'day') as DayIndicator) : 0
                }
                readOnly
                readOnlyBorderless
              />
            ) : (
              moment(value).format('HH:mm')
            )
          ) : null
      },
      {
        align: 'center',
        render: () => <i className="fa-light fa-arrow-right-long" style={{ color: '#3caef2' }} />
      },
      {
        title: 'Change to',
        key: 'request',
        dataIndex: 'request',
        render: (value: string, record: TimeLogRequestData<ISaveTimeLog>) =>
          record.field === 'timeOutRequest' ? (
            <TimeDayInput
              time={value ? moment(value) : null}
              day={
                value && record.requestTimeIn
                  ? (moment(value).diff(moment(record.requestTimeIn), 'day') as DayIndicator)
                  : 0
              }
              onChange={(value, day) =>
                handleFormDataChange({
                  [record.field]: moment(
                    `${moment(record.requestTimeIn).format('YYYY-MM-DD')}T${value?.format('HH:mm') || '00:00'}`
                  )
                    .add(day, 'day')
                    .format('YYYY-MM-DDTHH:mm')
                })
              }
            />
          ) : (
            <Input.Time
              value={value ? moment(value) : null}
              onChange={(value: moment.Moment | null) =>
                handleFormDataChange({
                  [record.field]: `${moment(formData[record.field]).format('YYYY-MM-DD')}T${
                    value?.format('HH:mm') || '00:00'
                  }`
                })
              }
            />
          )
      }
    ],
    [formData, handleFormDataChange]
  )

  return (
    <DrawerForm
      open={visible}
      title={data ? (isEditing ? 'Edit time log' : 'Time log') : 'Add time log'}
      okText={canModify ? (!isEditing ? 'Edit' : 'Save') : 'Close'}
      okDisabled={selectedLocationProjectCount > 0 && (!formData.projectId || projectsByLocation.length === 0)}
      onClose={onClose}
      confirmLoading={loading || projectsFetching}
      width={500}
      showDelete={data && isEditing ? true : false}
      onDelete={() => handleDelete(data)}
      className="mutate-time-log-drawer"
      formId="form-time-log"
    >
      <Form id="form-time-log" onFinish={!isEditing ? handleToggleEdit : handleOk}>
        <Row>
          <Col span={24}>
            {data ? (
              <Form.Item label="">
                <EmPublicPerson id={formData.employeeId} />
              </Form.Item>
            ) : (
              <Form.Item label="Employee" validateStatus={errors?.employeeId ? 'error' : ''} help={errors?.employeeId}>
                <EmSelect
                  ref={focusRef}
                  value={formData.employeeId}
                  onFetch={handleFetchEmployees}
                  onChange={(value: string) => handleFormDataChange({ ...EMPTY_FORM_DATA, employeeId: value })}
                />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row>
          <Col flex="200px">
            <Form.Item label="Date" validateStatus={errors?.timeIn ? 'error' : ''}>
              {!isEditing ? (
                formatDate(formData.timeIn)
              ) : (
                <Input.Date
                  allowClear={false}
                  value={formData.timeIn ? moment(formData.timeIn) : undefined}
                  onChange={(value: moment.Moment | null) => {
                    const outTime = moment(formData.timeOut)
                    const hour = outTime.hour()
                    const minute = outTime.minute()

                    handleFormDataChange({
                      timeIn: formData.timeIn ? value?.format('YYYY-MM-DDTHH:mm') : value?.format('YYYY-MM-DDT00:00'),
                      timeOut: value
                        ?.hour(0)
                        .minute(0)
                        ?.add(hour, 'hour')
                        ?.add(minute, 'minute')
                        ?.format('YYYY-MM-DDT00:00')
                    })
                  }}
                  disabledDate={current => current && current.isAfter(moment())}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        {!(isPending || isOngoing) && (
          <Row gutter={30}>
            <Col span={12}>
              <Form.Item label="Time in" validateStatus={errors?.timeIn ? 'error' : ''} help={errors?.timeIn}>
                {!isEditing ? (
                  moment(formData.timeIn).format('HH:mm')
                ) : (
                  <Input.Time
                    allowClear
                    value={formData.timeIn ? moment(formData.timeIn) : undefined}
                    onChange={(value: moment.Moment | null) =>
                      handleFormDataChange({
                        timeIn: `${moment(formData.timeIn || TODAY).format('YYYY-MM-DD')}T${
                          value?.format('HH:mm') || '00:00'
                        }`
                      })
                    }
                  />
                )}
                {data?.timeInOriginal && (
                  <SecondaryText block size="small">
                    Original: {moment(data.timeInOriginal).format('HH:mm')}
                  </SecondaryText>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Time out" validateStatus={errors?.timeOut ? 'error' : ''} help={errors?.timeOut}>
                <TimeDayInput
                  readOnly={!isEditing}
                  readOnlyBorderless={!isEditing}
                  date={formData.timeOut ? moment(formData.timeOut).format('YYYY-MM-DD') : undefined}
                  time={formData.timeOut ? moment(formData.timeOut) : undefined}
                  day={
                    formData.timeOut
                      ? (moment(formData.timeOut)
                          .startOf('day')
                          .diff(moment(formData.timeIn).startOf('day'), 'day') as DayIndicator)
                      : 0
                  }
                  onChange={(value, day) =>
                    handleFormDataChange({
                      timeOut: moment(
                        `${moment(formData.timeIn).format('YYYY-MM-DD')}T${value?.format('HH:mm') || '00:00'}`
                      )
                        .add(day, 'day')
                        .format('YYYY-MM-DDTHH:mm')
                    })
                  }
                />
                {data?.timeOutOriginal && (
                  <SecondaryText block size="small">
                    Original: {moment(data.timeOutOriginal).format('HH:mm')}
                  </SecondaryText>
                )}
              </Form.Item>
            </Col>
          </Row>
        )}
        {(isPending || isOngoing) && (
          <div className="time-log-request">
            <Table rowKey="field" dataSource={requestData} columns={columns} pagination={false} />
            {isPending && (
              <Alert message="This time log is pending for approval. Any changes by administrator will be considered as approving the request." />
            )}
            {isOngoing && (
              <Alert
                type="warning"
                message="This time log is on-going. Any changes by administrator will be considered as approving the request."
              />
            )}
          </div>
        )}
        <Row>
          <Col span={24}>
            <Form.Item>
              <Space>
                Auto assigned shift based on time in
                <Switch size="small" checked={autoShift} onChange={handleAutoShiftChange} disabled={!isEditing} />
              </Space>
              <Row hidden={autoShift}>
                <Col span={24}>
                  <Form.Item label="Shift" validateStatus={errors?.shiftId ? 'error' : ''} help={errors?.shiftId}>
                    <Row gutter={15} align="middle">
                      <Col span={12}>
                        {!isEditing ? (
                          shiftDict[formData.shiftId || '']?.name
                        ) : (
                          <ShiftKeyValues
                            value={formData.shiftId}
                            onChange={(shiftId: string) => handleFormDataChange({ shiftId })}
                          />
                        )}
                      </Col>
                      <Col span={12}>
                        <ShiftDayTime
                          shiftId={formData.shiftId}
                          dayCode={moment(formData.timeIn).format('ddd').toLowerCase()}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={30}>
          <Col span={isOutLocation ? 12 : 24}>
            <Form.Item
              label={`Location${isOutLocation ? ' (in)' : ''}`}
              validateStatus={errors?.locationId ? 'error' : ''}
              help={errors?.locationId}
            >
              {!isEditing ? (
                locationDict[formData.locationId]?.name || '-'
              ) : (
                <LocationKeyValues
                  value={formData.locationId}
                  onChange={(locationId: string) => {
                    if (isOutLocation) {
                      handleFormDataChange({ locationId })
                    } else {
                      const location = locations.find(l => l.id === locationId)
                      const projectsByLocation = getProjectsByLocation(location, projects)

                      if (projectsByLocation.length === 1) {
                        handleFormDataChange({ locationId, projectId: projectsByLocation[0].id })
                      } else {
                        handleFormDataChange({ locationId, projectId: '' })
                      }
                    }
                  }}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12} hidden={!isOutLocation}>
            <Form.Item
              label="Location (out)"
              validateStatus={errors?.outLocationId ? 'error' : ''}
              help={errors?.outLocationId}
            >
              {!isEditing ? (
                locationDict[formData.outLocationId || '']?.name || '-'
              ) : (
                <LocationKeyValues
                  value={formData.outLocationId}
                  onChange={(outLocationId: string) => {
                    const location = locations.find(l => l.id === outLocationId)
                    const projectsByLocation = getProjectsByLocation(location, projects)

                    if (projectsByLocation.length === 1) {
                      handleFormDataChange({ outLocationId, projectId: projectsByLocation[0].id })
                    } else {
                      handleFormDataChange({ outLocationId, projectId: '' })
                    }
                  }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        {formData.locationId && (
          <Row>
            <Col span={24}>
              <Form.Item
                label="Project"
                validateStatus={errors?.projectId ? 'error' : ''}
                help={errors?.projectId}
                noStyle={selectedLocationProjectCount === 0 && projectsByLocation.length === 0}
              >
                {projectsByLocation.length > 0 &&
                  (!isEditing ? (
                    projectDict[formData.projectId]?.name || '-'
                  ) : (
                    <Select
                      showSearch
                      optionFilterProp="title"
                      loading={projectsFetching}
                      value={formData.projectId}
                      onChange={(projectId: string) => handleFormDataChange({ projectId })}
                    >
                      {projectsByLocation.map(p => (
                        <Select.Option key={p.id} value={p.id || ''} title={p.name}>
                          {isInactive(p.inactiveDate) && <InactiveTag />}
                          {p.name}
                        </Select.Option>
                      ))}
                    </Select>
                  ))}
                {selectedLocationProjectCount > 0 && projectsByLocation.length === 0 && 'No project'}
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24}>
            <Form.Item label="Notes" validateStatus={errors?.notes ? 'error' : ''} help={errors?.notes}>
              {!isEditing ? (
                formData.notes || '-'
              ) : (
                <Input.TextArea
                  rows={2}
                  value={formData.notes}
                  onChange={(value?: ChangeEvent<HTMLTextAreaElement>) =>
                    handleFormDataChange({ notes: value?.target.value })
                  }
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <TimeLogCurrentApprover
          approvers={data?.currentApprovers || []}
          hidden={data?.approvalStatus !== TimeLogStatus.Pending}
        />
        <TimeLogApprovalHistories
          histories={approvalHistories}
          hidden={isNew}
          {...pick(data, 'submitterName', 'submittedDate')}
        />
      </Form>
    </DrawerForm>
  )
}

const mapToRequest = (data: TimeLogRowState): ISaveTimeLog => ({
  id: data.id,
  employeeId: data.employeeId,
  timeIn: data.timeIn,
  timeOut: data.timeOut,
  locationId: data.locationId,
  projectId: data.projectId,
  outLocationId: data.outLocationId,
  shiftId: data.shiftId,
  notes: data.notes
})
