import React, { CSSProperties, FC, useCallback, useEffect, useState } from 'react'
import { Form, Space, Switch } from '~/core-components'
import { Col, EditableCard, EditableCardState, Row } from '~/components'
import { usePermissionGate } from '~/features/iam/hooks'
import { Permission, PermissionAction } from '~/constants'
import { dispatch } from '~/stores/store'
import { Errors, ActionResult } from '~/types/store'
import { IEmployeeLeave, EmployeeState } from '../../../types'
import { mapEmployeeStateToEmployeeLeave } from '../../../types/employee.mapper'
import { updateEmployeeLeave } from '../../../actions'

interface EmployeeLeaveProps {
  employee?: EmployeeState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

const EMPTY_FORM_DATA: IEmployeeLeave = {
  isAllDayLeaveAllowed: false
}

const cardStyle: CSSProperties = { margin: 24 }

export const EmployeeLeave: FC<EmployeeLeaveProps> = ({ employee, onEdit, onSave, onCancel }: EmployeeLeaveProps) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const [formData, setFormData] = useState<IEmployeeLeave>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const canView = usePermissionGate(Permission.employeeLeaveGroup)
  const canModify = usePermissionGate(Permission.employeeLeaveGroup, PermissionAction.Modify)

  useEffect(() => {
    if (employee) {
      const { isAllDayLeaveAllowed } = employee
      setFormData({ isAllDayLeaveAllowed })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [employee])

  const handleFormDataChange = useCallback(
    (updates: { [field: string]: any }) => setFormData(formData => ({ ...formData, ...updates })),
    []
  )

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (employee) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(updateEmployeeLeave(employee.id, mapEmployeeStateToEmployeeLeave(employee), formData))
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [employee, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (employee) {
      const { isAllDayLeaveAllowed } = employee
      setFormData({ isAllDayLeaveAllowed })
    }
  }, [employee, onCancel])

  const readOnly = cardState !== 'editing'

  if (!canView) return null

  return (
    <EditableCard
      title="Leave information"
      style={cardStyle}
      bodyStyle={{ paddingBottom: employee ? 6 : 24, paddingTop: 6 }}
      state={canModify ? cardState : 'readonly'}
      formId="form-em-leave"
      onEdit={handleEdit}
      onSave={handleSave}
      onCancel={handleCancel}
    >
      <Row gutter={30}>
        <Col span={12}>
          <Form.Item validateStatus={errors?.isAllDayLeaveAllowed ? 'error' : ''} help={errors?.isAllDayLeaveAllowed}>
            <Space>
              <Switch
                checkedChildren="Yes"
                unCheckedChildren="No"
                disabled={readOnly}
                checked={formData.isAllDayLeaveAllowed}
                onChange={(isAllDayLeaveAllowed: boolean) => {
                  handleFormDataChange({ isAllDayLeaveAllowed })
                }}
              />
              <label>Leave eligibility on all days</label>
            </Space>
          </Form.Item>
        </Col>
      </Row>
    </EditableCard>
  )
}
