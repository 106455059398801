import React, { ChangeEvent, FC, useCallback, useState } from 'react'
import { Button, Form, Input } from '~/core-components'
import { Col, Row } from '~/components'
import { ActionResult, Errors } from '~/types/store'
import { dispatch } from '~/stores/store'
import { updatePassword } from '../../../actions'
import { IUpdatePassword } from '../../../types'
import { TwoFactorAuth } from './TwoFactorAuth'

interface EditPasswordFormProps {
  label?: string
  onComplete?: () => void
}

interface FormData extends IUpdatePassword {}

const EMPTY_FORM_DATA: FormData = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: ''
}

export const EditPasswordForm: FC<EditPasswordFormProps> = ({ label = 'Update password', onComplete }) => {
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const [saving, setSaving] = useState(false)

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleSave = useCallback(async () => {
    let result: ActionResult | undefined
    setSaving(true)
    try {
      result = await dispatch(updatePassword(formData))
      setErrors(undefined)
    } finally {
      setSaving(false)
    }

    if (result?.errors) {
      setErrors(result.errors)
    }

    if (!result?.errors) {
      setFormData(EMPTY_FORM_DATA)
      typeof onComplete === 'function' && onComplete()
    }
  }, [formData, onComplete])

  return (
    <div className="edit-password-form">
      <Form id="form-change-pwd" onFinish={handleSave}>
        <Row>
          <Col flex="300px">
            <Form.Item
              label="Current password"
              validateStatus={errors?.currentPassword ? 'error' : ''}
              help={errors?.currentPassword}
            >
              <Input
                type="password"
                value={formData.currentPassword}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ currentPassword: event.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex="300px">
            <Form.Item
              label="New password"
              validateStatus={errors?.newPassword || errors?.password ? 'error' : ''}
              help={errors?.newPassword || errors?.password}
            >
              <Input
                type="password"
                value={formData.newPassword}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ newPassword: event.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row hidden={!formData.newPassword}>
          <Col flex="300px">
            <Form.Item
              label="Confirm password"
              validateStatus={errors?.confirmPassword ? 'error' : ''}
              help={errors?.confirmPassword}
            >
              <Input
                type="password"
                value={formData.confirmPassword}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleFormDataChange({ confirmPassword: event.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button.ActionGroup>
              <Button type="primary" loading={saving} form="form-change-pwd" htmlType="submit">
                {label}
              </Button>
            </Button.ActionGroup>
          </Col>
        </Row>
      </Form>
      <Form id="form-two-factor">
        <Row style={{ marginTop: 20 }}>
          <Col>
            <Form.Item label="Two-step authentication">
              <TwoFactorAuth />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}
