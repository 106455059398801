import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import confirm from 'antd/lib/modal/confirm'
import { Button, Form, Input, Space } from '~/core-components'
import { Col, EditableCard, EditableCardState, Row } from '~/components'
import { dispatch } from '~/stores/store'
import { GoogleCalendarState, IGoogleCalendarInfo } from '~/features/leave/types'
import {
  disconnectGoogleCalendar,
  initiateGoogleCalendarAuthentication,
  updateGoogleCalendar
} from '~/features/leave/actions'
import { useFocus } from '~/hooks'
import { ActionResult, Errors } from '~/types/store'
import { usePermissionGate } from '~/features/iam'
import { Permission, PermissionAction } from '~/constants'
import { mapGoogleCalendarStateToGoogleCalendarInfo } from '~/features/leave/types/google-calendar.mapper'
import { useGoogleCalendarConnection } from '~/features/leave/hooks'
import { GoogleCalendarSyncDrawer } from './GoogleCalendarSyncDrawer'

interface GoogleCalendarAccountProps {
  googleCalendar?: GoogleCalendarState
  onEdit?: () => void
  onSave?: () => void
  onCancel?: () => void
}

export const EMPTY_FORM_DATA: IGoogleCalendarInfo = {
  name: ''
}

export const GoogleCalendarAccount: FC<GoogleCalendarAccountProps> = ({ googleCalendar, onEdit, onSave, onCancel }) => {
  const [cardState, setCardState] = useState<EditableCardState>()
  const readOnly = cardState !== 'editing' && cardState !== 'saving'
  const [focusRef] = useFocus(!readOnly)
  const [formData, setFormData] = useState<IGoogleCalendarInfo>(EMPTY_FORM_DATA)
  const [errors, setErrors] = useState<Errors>()
  const canModify = usePermissionGate(Permission.googleCalendar, PermissionAction.Modify)
  const [connecting, setConnecting] = useState(false)
  const [connection, loading] = useGoogleCalendarConnection(googleCalendar?.id || '')
  const [drawerVisible, setDrawerVisible] = useState(false)

  useEffect(() => {
    if (googleCalendar) {
      const { name } = googleCalendar
      setFormData({ name })
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [googleCalendar])

  const handleConnect = useCallback(async () => {
    if (googleCalendar) {
      try {
        setConnecting(true)
        await dispatch(initiateGoogleCalendarAuthentication(googleCalendar.id))
      } finally {
        setConnecting(false)
      }
    }
  }, [googleCalendar])

  const handleDisconnect = useCallback(() => {
    if (googleCalendar) {
      try {
        setConnecting(true)
        confirm({
          title: 'Disconnect Google account',
          content:
            'By disconnecting your Google account, all integrations associated with this account will also be disconnected. Please confirm if you wish to proceed.',
          onOk: async () => {
            await dispatch(disconnectGoogleCalendar(googleCalendar.id))
          },
          okText: 'Disconnect',
          okType: 'danger'
        })
      } finally {
        setConnecting(false)
      }
    }
  }, [googleCalendar])

  const handleEdit = useCallback(() => {
    setCardState('editing')
    typeof onEdit === 'function' && onEdit()
  }, [onEdit])

  const handleSave = useCallback(async () => {
    if (googleCalendar) {
      setCardState('saving')
      setErrors(undefined)

      typeof onSave === 'function' && onSave()

      let result: ActionResult | undefined
      try {
        result = await dispatch(
          updateGoogleCalendar(googleCalendar.id, mapGoogleCalendarStateToGoogleCalendarInfo(googleCalendar), formData)
        )
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [googleCalendar, formData, onSave])

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel()
    setCardState(undefined)
    setErrors(undefined)

    if (googleCalendar) {
      const { name } = googleCalendar
      setFormData({ name })
    }
  }, [googleCalendar, onCancel])

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleSynchronize = useCallback(async () => {
    setDrawerVisible(true)
  }, [])

  const handleDrawerClose = useCallback(() => {
    setDrawerVisible(false)
  }, [])

  return (
    <>
      <EditableCard
        className="google-calendar__form"
        title="Account"
        state={canModify ? cardState : 'readonly'}
        formId="form-google-calendar-account"
        formLayout="horizontal"
        formLabelCol={{ flex: '130px' }}
        onEdit={handleEdit}
        onSave={handleSave}
        onCancel={handleCancel}
      >
        <Row>
          <Col span={18}>
            <Form.Item label="Name" validateStatus={errors?.name ? 'error' : ''} help={errors?.name}>
              <Input
                ref={focusRef}
                value={formData.name}
                readOnly={readOnly}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleFormDataChange({ name: event.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={18}>
            <Form.Item label="Google account">
              <Input value={googleCalendar?.account} disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row hidden={!readOnly} className="connection-area">
          <Col span={18}>
            {connection?.isConnected ? (
              <Space>
                <Button danger loading={connecting || loading} onClick={handleDisconnect}>
                  Disconnect
                </Button>
                <Button loading={loading} onClick={handleSynchronize}>
                  Sync now
                </Button>
              </Space>
            ) : (
              <Button type="primary" loading={connecting || loading} onClick={handleConnect}>
                Connect
              </Button>
            )}
          </Col>
        </Row>
      </EditableCard>
      <GoogleCalendarSyncDrawer open={drawerVisible} id={googleCalendar?.id} onClose={handleDrawerClose} />
    </>
  )
}
