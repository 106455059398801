import { FC, KeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment-timezone'
import classNames from 'classnames'
import confirm from 'antd/lib/modal/confirm'
import { LoadingOutlined, WarningOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {
  Button,
  Card,
  Form,
  Input,
  Link,
  PageHeader,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
  Tag,
  Tooltip
} from '~/core-components'
import {
  Col,
  DayIndicator,
  DocumentTitle,
  EditableCardState,
  InfoTooltip,
  NewTabLinkIcon,
  Person,
  Row,
  TimeDayInput
} from '~/components'
import { useSysOptions } from '~/features/employee'
import { usePermissionGate } from '~/features/iam'
import { useLeaveTypes, useLeaveTypesDict } from '~/features/leave'
import { Permission, PermissionAction, WorkSchedule, emptyGuid } from '~/constants'
import { ATT_ROUTES, SETTINGS_ROUTES } from '~/routes/routes'
import { ActionResult, Errors, StoreState } from '~/types/store'
import { dispatch } from '~/stores/store'
import { convertToTimeDay, formatHourMinute, getBaseUrl } from '~/utils'
import {
  useBreaksDict,
  useCalendarsDict,
  useDailyRecordEmployees,
  useDailyRecordsByEmployeeDate,
  useOtConfigsDict,
  useTeConfigsDict
} from '../../hooks'
import { selectDayCode } from '../../selectors'
import { deleteDailyRecord, lockDailyRecord, processDaily, unlockDailyRecord, updateDailyRecord } from '../../actions'
import { refetchDailyRecordsByEmployeeView } from '../../reducers'
import { DailyRecordState, IUpdateDailyRecord } from '../../types'
import { ShiftName } from '../Shifts/components/ShiftName'
import { ShiftDayTime } from '../Shifts/components/ShiftDayTime'
import { ViewTimeLogsLink } from './components/ViewTimeLogsLink'
// import { DailyRecordLabels } from './components/DailyRecordLabels'
import './DailyRecord.less'

export interface DailyRecordProps {
  clockDate?: string
  employeeId?: string
  shiftId?: string
  openAs?: 'drawer' | 'page'
}

interface DailyRecordParams {
  clockDate: string
  employeeId: string
  shiftId?: string
}

interface FormData {
  id: string
  startTime: string
  startDay: number
  endTime: string
  endDay: number

  // for work_schedule = 'calendar', allow overwrite
  workStatusType: string
  shiftId?: string
  breakId?: string
  locationId?: string

  latenessDeduct: number
  latenessNonDeduct: number
  latenessLeaveTypeId?: string
  undertimeDeduct: number
  undertimeNonDeduct: number
  undertimeLeaveTypeId?: string
  ot01: number
  ot02: number
  ot03: number
  ot04: number
  ot05: number
  ot06: number
  ot07: number
  ot08: number
  ot09: number
  ot10: number
  te01: number
  te02: number
  te03: number
  te04: number
  te05: number
  te06: number
  te07: number
  te08: number
  te09: number
  te10: number
  notes?: string
}

const EMPTY_FORM_DATA: FormData = {
  id: '',
  startTime: '',
  startDay: 0,
  endTime: '',
  endDay: 0,
  workStatusType: '',
  shiftId: '',
  breakId: '',
  locationId: '',
  latenessDeduct: 0,
  latenessNonDeduct: 0,
  latenessLeaveTypeId: undefined,
  undertimeDeduct: 0,
  undertimeNonDeduct: 0,
  undertimeLeaveTypeId: undefined,
  ot01: 0,
  ot02: 0,
  ot03: 0,
  ot04: 0,
  ot05: 0,
  ot06: 0,
  ot07: 0,
  ot08: 0,
  ot09: 0,
  ot10: 0,
  te01: 0,
  te02: 0,
  te03: 0,
  te04: 0,
  te05: 0,
  te06: 0,
  te07: 0,
  te08: 0,
  te09: 0,
  te10: 0,
  notes: ''
}

const OT_LIST = ['ot01', 'ot02', 'ot03', 'ot04', 'ot05', 'ot06', 'ot07', 'ot08', 'ot09', 'ot10']
const TE_LIST = ['te01', 'te02', 'te03', 'te04', 'te05', 'te06', 'te07', 'te08', 'te09', 'te10']
const baseUrl = getBaseUrl('/filestore')

export const DailyRecord: FC<DailyRecordProps> = ({
  employeeId: _employeeId,
  clockDate: _clockDate,
  shiftId: _shiftId,
  openAs = 'page'
}) => {
  const { employeeId: pEmployeeId, clockDate: pClockDate, shiftId: pShiftId } = useParams<DailyRecordParams>()

  const employeeId = _employeeId || pEmployeeId
  const clockDate = _clockDate || pClockDate
  const shiftId = _shiftId || pShiftId

  const history = useHistory()
  const [cardState, setCardState] = useState<EditableCardState>()
  const [errors, setErrors] = useState<Errors>()
  const [formData, setFormData] = useState<FormData>(EMPTY_FORM_DATA)
  const canModify = usePermissionGate(Permission.attDailyRecord, PermissionAction.Modify)
  const readOnly = cardState !== 'editing' && cardState !== 'saving'
  const [activeShiftId, setActiveShiftId] = useState<string | null>(null)
  const [locking, setLocking] = useState<string>()

  const processing = useSelector((state: StoreState) => state.attendance.dailyProcessing)
  const [employees, employeesLoading] = useDailyRecordEmployees(clockDate)
  const [dailyRecords, loading] = useDailyRecordsByEmployeeDate(clockDate, employeeId)
  const isEmpty = !loading && dailyRecords.length === 0
  const dailyRecord = useMemo(() => dailyRecords.find(d => d.shiftId === activeShiftId), [dailyRecords, activeShiftId])
  const dayCode = useSelector(selectDayCode)(dailyRecord?.id)
  const isLocked = Boolean(dailyRecord?.lockedBy)

  const [breakDict] = useBreaksDict()
  const [workStatusTypeDict] = useSysOptions('work_status_type')
  const [workScheduleDict] = useSysOptions('work_schedule')
  const [calendarDict] = useCalendarsDict()
  const [leaveTypes] = useLeaveTypes()
  const [leaveTypesDict] = useLeaveTypesDict()
  const [otConfigs] = useOtConfigsDict()
  const [teConfigs] = useTeConfigsDict()

  const canModifyShift = usePermissionGate(Permission.shift, PermissionAction.Modify)
  const canModifyBreak = usePermissionGate(Permission.break, PermissionAction.Modify)
  const canModifyCalendar = usePermissionGate(Permission.calendar, PermissionAction.Modify)

  const routes = useMemo(
    () => [
      {
        path: ATT_ROUTES.tab.replace(':tab?', 'daily'),
        breadcrumbName: 'Overview'
      },
      {
        path: '',
        breadcrumbName: 'Daily record'
      }
    ],
    []
  )

  useEffect(() => {
    if (dailyRecords.length > 0) {
      const isShiftExist = dailyRecords.some(da => da.shiftId === shiftId)
      if (isShiftExist) {
        setActiveShiftId(shiftId ?? null)
      } else if (!activeShiftId) {
        setActiveShiftId(dailyRecords[0].shiftId ?? null)
      }
    }
  }, [activeShiftId, shiftId, dailyRecords])

  useEffect(() => {
    if (openAs === 'drawer') return

    if (activeShiftId) {
      history.push(
        ATT_ROUTES.dailyRecord
          .replace(':clockDate', clockDate)
          .replace(':employeeId', employeeId)
          .replace(':shiftId?', activeShiftId)
      )
    }
  }, [history, openAs, clockDate, employeeId, activeShiftId])

  const resetFormData = useCallback(() => {
    if (dailyRecord) {
      setCardState(undefined)
      setErrors(undefined)

      const {
        id,
        clockDate,
        timeIn,
        timeInAdj,
        timeOut,
        timeOutAdj,
        workStatusType,
        shiftId,
        breakId,
        locationId,
        latenessDeduct,
        latenessNonDeduct,
        latenessLeaveTypeId,
        undertimeDeduct,
        undertimeNonDeduct,
        undertimeLeaveTypeId,
        ot01,
        ot02,
        ot03,
        ot04,
        ot05,
        ot06,
        ot07,
        ot08,
        ot09,
        ot10,
        te01,
        te02,
        te03,
        te04,
        te05,
        te06,
        te07,
        te08,
        te09,
        te10,
        notes
      } = dailyRecord

      const startTime = timeInAdj || timeIn
      const endTime = timeOutAdj || timeOut

      const startTimeDay = convertToTimeDay(startTime, clockDate)
      const endTimeDay = convertToTimeDay(endTime, clockDate)

      setFormData({
        id,
        startTime: startTimeDay.time,
        startDay: startTimeDay.day,
        endTime: endTimeDay.time,
        endDay: endTimeDay.day,
        workStatusType,
        shiftId,
        breakId,
        locationId,
        latenessDeduct,
        latenessNonDeduct,
        latenessLeaveTypeId,
        undertimeDeduct,
        undertimeNonDeduct,
        undertimeLeaveTypeId,
        ot01,
        ot02,
        ot03,
        ot04,
        ot05,
        ot06,
        ot07,
        ot08,
        ot09,
        ot10,
        te01,
        te02,
        te03,
        te04,
        te05,
        te06,
        te07,
        te08,
        te09,
        te10,
        notes
      })
    }
  }, [dailyRecord])

  useEffect(() => {
    if (dailyRecord) {
      resetFormData()
    } else {
      setFormData(EMPTY_FORM_DATA)
    }
  }, [dailyRecord, resetFormData])

  const handleShiftChange = useCallback((event: RadioChangeEvent) => {
    setActiveShiftId(event.target.value)
  }, [])

  const handleCancel = useCallback(() => {
    if (dailyRecord) {
      resetFormData()
    }
  }, [dailyRecord, resetFormData])

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Escape') {
        handleCancel()
      }
    },
    [handleCancel]
  )

  const handleFormDataChange = useCallback((updates: { [field: string]: any }) => {
    setErrors(undefined)
    setFormData(data => ({ ...data, ...updates }))
  }, [])

  const handleDateChange = useCallback(
    (value: moment.Moment | null) => {
      setActiveShiftId(null)

      if (openAs === 'drawer') return

      history.push(
        ATT_ROUTES.dailyRecord
          .replace(':clockDate', value?.format('YYYY-MM-DD') || '')
          .replace(':employeeId', employeeId)
          .replace(':shiftId?', '')
      )
    },
    [history, openAs, employeeId]
  )

  const handlePrevDay = useCallback(() => {
    setActiveShiftId(null)

    if (openAs === 'drawer') return

    history.push(
      ATT_ROUTES.dailyRecord
        .replace(':clockDate', moment(clockDate).add(-1, 'day').format('YYYY-MM-DD'))
        .replace(':employeeId', employeeId)
        .replace(':shiftId?', '')
    )
  }, [history, openAs, clockDate, employeeId])

  const handleNextDay = useCallback(() => {
    setActiveShiftId(null)

    if (openAs === 'drawer') return

    history.push(
      ATT_ROUTES.dailyRecord
        .replace(':clockDate', moment(clockDate).add(1, 'day').format('YYYY-MM-DD'))
        .replace(':employeeId', employeeId)
        .replace(':shiftId?', '')
    )
  }, [history, openAs, clockDate, employeeId])

  const handleProcessNew = useCallback(async () => {
    await dispatch(processDaily(clockDate, clockDate, [employeeId], 'all'))
    dispatch(refetchDailyRecordsByEmployeeView(employeeId))
  }, [clockDate, employeeId])

  const handleProcessExisting = useCallback(async () => {
    await dispatch(processDaily(clockDate, clockDate, [employeeId], 'existing'))
    dispatch(refetchDailyRecordsByEmployeeView(employeeId))
  }, [clockDate, employeeId])

  const handleLock = useCallback(async () => {
    if (dailyRecord) {
      setLocking(dailyRecord.id)
      try {
        if (dailyRecord.lockedBy) {
          await dispatch(unlockDailyRecord(dailyRecord.id))
        } else {
          await dispatch(lockDailyRecord(dailyRecord.id))
        }
      } finally {
        setLocking(undefined)
      }
    }
  }, [dailyRecord])

  const handleEdit = useCallback(() => {
    if (isLocked) return
    setCardState('editing')
  }, [isLocked])

  const handleSave = useCallback(async () => {
    if (dailyRecord) {
      setCardState('saving')
      setErrors(undefined)

      let result: ActionResult | undefined
      try {
        const saved = mapStateToDto(dailyRecord)
        const changes = mapFormToDto(dailyRecord, formData)
        const clockTimeChanged =
          (changes.timeInAdj && saved.timeInAdj && !moment(changes.timeInAdj).isSame(saved.timeInAdj, 'minute')) ||
          (saved.timeInAdj == null && changes.timeInAdj != null) ||
          (changes.timeOutAdj && saved.timeOutAdj && !moment(changes.timeOutAdj).isSame(saved.timeOutAdj, 'minute')) ||
          (saved.timeOutAdj == null && changes.timeOutAdj != null)

        result = await dispatch(updateDailyRecord(dailyRecord.id, saved, changes))

        if (!result?.errors) {
          if (clockTimeChanged) {
            confirm({
              title: 'Recalculate daily record',
              content:
                'Changes have been made to the start time or end time. Would you like to recalculate the record to ensure that lateness, undertime, and overtime are adjusted accordingly?',
              onOk: async () => {
                await handleProcessExisting()
              },
              okText: 'Recalculate'
            })
          } else {
            confirm({
              title: 'Lock daily record',
              content:
                'Do you want to lock the attendance record? If you choose not to lock it, subsequent recalculations may overwrite the changes you made.',
              onOk: async () => {
                await handleLock()
              },
              okText: 'Lock'
            })
          }
        }
      } catch {
        setCardState('editing')
      }

      if (result?.errors) {
        setCardState('editing')
        setErrors(result.errors)
      }

      if (!result?.errors) {
        setCardState(undefined)
      }
    }
  }, [dailyRecord, formData, handleProcessExisting, handleLock])

  const handleDelete = useCallback(() => {
    if (dailyRecord?.id) {
      confirm({
        title: 'Delete daily record',
        content: `Do you want to delete daily record "${moment(dailyRecord.clockDate).format('DD MMM YYYY (ddd)')}"?`,
        onOk: async () => {
          const result: ActionResult | undefined = await dispatch(deleteDailyRecord(dailyRecord.id))

          if (openAs === 'drawer') return

          if (!result?.errors) {
            history.push(ATT_ROUTES.tab.replace(':tab', 'daily'))
          }
        },
        okText: 'Delete',
        okType: 'danger'
      })
    }
  }, [history, openAs, dailyRecord])

  return (
    <div id="daily-record" className="daily-record">
      <DocumentTitle title="Daily Record" />
      <PageHeader
        title={
          <div className="daily-record__header">
            <Row gutter={15} align="bottom">
              <Col className="daily-record__header-left">
                <Space direction="vertical">
                  <div className="daily-record__header-date">
                    <Input.Date
                      className="daily-record__header-date-select"
                      allowClear={false}
                      inputReadOnly={openAs === 'drawer'}
                      bordered={openAs === 'page'}
                      format="DD MMM YYYY (ddd)"
                      value={moment(clockDate)}
                      onChange={handleDateChange}
                      {...(openAs === 'drawer' ? { suffixIcon: null } : {})}
                    />
                    {openAs === 'page' && (
                      <>
                        <Tooltip title="Previous day">
                          <Button
                            className="daily-record__header-date-prev"
                            icon={<i className="fal fa-chevron-left" />}
                            onClick={handlePrevDay}
                          />
                        </Tooltip>
                        <Tooltip title="Next day">
                          <Button
                            className="daily-record__header-date-next"
                            icon={<i className="fal fa-chevron-right" />}
                            onClick={handleNextDay}
                          />
                        </Tooltip>
                      </>
                    )}
                  </div>
                  <Select
                    className="daily-record__header-em-select"
                    showSearch
                    allowClear={false}
                    readOnly={openAs === 'drawer'}
                    bordered={openAs === 'page'}
                    loading={employeesLoading}
                    optionFilterProp="title"
                    onChange={(id: string) => {
                      setActiveShiftId(null)

                      if (openAs === 'drawer') return

                      history.push(
                        ATT_ROUTES.dailyRecord
                          .replace(':clockDate', clockDate)
                          .replace(':employeeId', id)
                          .replace(':shiftId?', '')
                      )
                    }}
                    value={employeeId}
                  >
                    {employees?.map(em => (
                      <Select.Option key={em.id} value={em.id} title={em.name}>
                        <Person
                          name={em.name}
                          description={em.description}
                          photo={
                            em.photoId && em.photoId !== emptyGuid
                              ? `${baseUrl}/file/${em.photoId}/thumbnailphoto/36`
                              : undefined
                          }
                        />
                      </Select.Option>
                    ))}
                  </Select>
                </Space>
              </Col>
              <Col flex="1">
                <Space direction="vertical">
                  {dailyRecords.length > 1 && (
                    <Radio.Group value={activeShiftId} onChange={handleShiftChange}>
                      <Space direction="vertical">
                        {dailyRecords.map(d => (
                          <Radio value={d.shiftId}>
                            <Space>
                              <ShiftName id={d.shiftId} fallbackShiftName="Unknown shift" hideNewTab />
                              <ShiftDayTime shiftId={d.shiftId} dayCode={dayCode} />
                            </Space>
                          </Radio>
                        ))}
                      </Space>{' '}
                    </Radio.Group>
                  )}
                  <Row className="daily-record__header-summary">
                    <Col className="daily-record__header-summary-box">
                      <NewTabLinkIcon
                        path={SETTINGS_ROUTES.shift.replace(':id', dailyRecord?.shiftId || '')}
                        hidden={!canModifyShift}
                      >
                        <label>Shift</label>
                      </NewTabLinkIcon>
                      <div className="daily-record__header-summary-value">
                        <ShiftName id={dailyRecord?.shiftId} hideNewTab />
                        <ShiftDayTime shiftId={dailyRecord?.shiftId} dayCode={dayCode} />
                      </div>
                    </Col>
                    <Col className="daily-record__header-summary-box">
                      <NewTabLinkIcon path={SETTINGS_ROUTES.breaks} hidden={!canModifyBreak}>
                        <label>Break</label>
                      </NewTabLinkIcon>
                      <div className="daily-record__header-summary-value">
                        {breakDict[dailyRecord?.breakId || '']?.name}
                      </div>
                    </Col>
                    <Col className="daily-record__header-summary-box">
                      <label>Working status</label>
                      <div className="daily-record__header-summary-value">
                        <div>{workStatusTypeDict[dailyRecord?.workStatusType || '']?.value}</div>
                        {dailyRecord?.isHoliday && (
                          <div>
                            <Tag color="#f2bd3a">Holiday</Tag>
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col className="daily-record__header-summary-box">
                      <NewTabLinkIcon
                        path={SETTINGS_ROUTES.workCalendar.replace(':id', dailyRecord?.calendarId || '')}
                        hidden={!canModifyCalendar}
                      >
                        <label>Calendar</label>
                      </NewTabLinkIcon>
                      <div className="daily-record__header-summary-value">
                        {dailyRecord?.calendarId ? (
                          <>
                            <div>{calendarDict[dailyRecord?.calendarId || '']?.name}</div>
                            {dailyRecord?.workSchedule === WorkSchedule.schedule && (
                              <div>
                                <Tag color="#d7c7f6">{workScheduleDict[dailyRecord?.workSchedule || '']?.value}</Tag>
                              </div>
                            )}
                          </>
                        ) : (
                          <>{workScheduleDict[dailyRecord?.workSchedule || '']?.value}</>
                        )}
                      </div>
                    </Col>
                    <Col className="daily-record__header-summary-lock">
                      {canModify && (
                        <Tooltip title={isLocked ? 'Locked' : 'Unlocked'}>
                          <Link
                            className={classNames('daily-record__lock', {
                              'daily-record__lock--locked': !!dailyRecord?.lockedBy
                            })}
                            onClick={handleLock}
                          >
                            {dailyRecord && locking === dailyRecord?.id ? (
                              <LoadingOutlined />
                            ) : isLocked ? (
                              <i className="fal fa-lock" />
                            ) : (
                              <i className="fal fa-lock-open" />
                            )}
                          </Link>
                        </Tooltip>
                      )}
                    </Col>
                  </Row>
                </Space>
              </Col>
              <Col hidden={!canModify || isEmpty || isLocked}>
                <Space direction="vertical" align="end" className="daily-record__header-actions">
                  {cardState === 'editing' || cardState === 'saving' ? (
                    <>
                      <Button type="primary" loading={cardState === 'saving'} onClick={handleSave}>
                        Save
                      </Button>
                      <Button onClick={handleCancel}>Cancel</Button>
                    </>
                  ) : (
                    <>
                      <Button type="primary" onClick={handleEdit}>
                        Edit
                      </Button>
                      {dailyRecord?.isDirty ? (
                        <Tooltip title="This record has not been recalculated after changes made to the start time or end time. Lateness, undertime, and overtime values may be inaccurate.">
                          <Button onClick={handleProcessExisting} loading={processing} icon={<WarningOutlined />}>
                            Recalculate
                          </Button>
                        </Tooltip>
                      ) : (
                        <Button onClick={handleProcessExisting} loading={processing}>
                          Recalculate
                        </Button>
                      )}
                    </>
                  )}
                </Space>
              </Col>
            </Row>
          </div>
        }
        containerId="daily-record"
        breadcrumb={openAs === 'page' ? { routes } : undefined}
        extra={
          canModify &&
          cardState === 'editing' && (
            <Button key="delete" onClick={handleDelete}>
              Delete
            </Button>
          )
        }
      />

      <div className="daily-record__body">
        {isEmpty && (
          <div className="daily-record__body-empty">
            <Card>No daily record found</Card>
            <Button type="primary" onClick={handleProcessNew} loading={processing}>
              Add daily record
            </Button>
          </div>
        )}
        <Form className="daily-record__body-form" hidden={isEmpty}>
          <div className="daily-record__body-left">
            <Card onKeyDown={handleKeyDown} onDoubleClick={handleEdit}>
              <div className="section-title">
                <Space>
                  Working hours
                  {dailyRecord?.timeIn && <ViewTimeLogsLink dailyRecordId={dailyRecord?.id} />}
                </Space>
              </div>
              <Row gutter={15}>
                <Col flex="126px">
                  <Form.Item
                    label={
                      <>
                        Start
                        {dailyRecord?.timeInAdj && dailyRecord?.timeIn && (
                          <InfoTooltip
                            title={`Original start time: ${
                              dailyRecord?.timeIn ? moment(dailyRecord.timeIn).format('HH:mm') : ''
                            }`}
                          />
                        )}
                      </>
                    }
                    validateStatus={errors?.timeInAdj ? 'error' : ''}
                    help={errors?.timeInAdj}
                  >
                    <Space size={0}>
                      <TimeDayInput
                        time={formData.startTime ? moment(formData.startTime, 'HH:mm') : undefined}
                        day={formData.startDay as DayIndicator}
                        readOnly={readOnly}
                        readOnlyBorderless
                        onChange={(startTime, startDay) =>
                          handleFormDataChange({ startTime: startTime?.format('HH:mm'), startDay })
                        }
                      />
                    </Space>
                  </Form.Item>
                </Col>
                <Col flex="126px">
                  <Form.Item
                    label={
                      <>
                        End
                        {dailyRecord?.timeOutAdj && dailyRecord?.timeOut && (
                          <InfoTooltip
                            title={`Original end time: ${
                              dailyRecord?.timeOut ? moment(dailyRecord.timeOut).format('HH:mm') : ''
                            }`}
                          />
                        )}
                      </>
                    }
                    validateStatus={errors?.timeOutAdj ? 'error' : ''}
                    help={errors?.timeOutAdj}
                  >
                    <Space size={0}>
                      <TimeDayInput
                        time={formData.endTime ? moment(formData.endTime, 'HH:mm') : undefined}
                        day={formData.endDay as DayIndicator}
                        readOnly={readOnly}
                        readOnlyBorderless
                        onChange={(endTime, endDay) =>
                          handleFormDataChange({ endTime: endTime?.format('HH:mm'), endDay })
                        }
                      />
                    </Space>
                  </Form.Item>
                </Col>
                <Col flex="126px">
                  <Form.Item label="Actual hours">{formatHourMinute(dailyRecord?.workHourActual)}</Form.Item>
                </Col>
                <Col flex="126px">
                  <Form.Item label="Paid hours">{formatHourMinute(dailyRecord?.workHourPaid)}</Form.Item>
                </Col>
              </Row>
              <div className="section-title">Lateness</div>
              <Row gutter={15}>
                <Col flex="126px">
                  <Form.Item label="Actual">{formatHourMinute(dailyRecord?.latenessActual, 'minute')}</Form.Item>
                </Col>
                <Col flex="126px">
                  <Form.Item
                    label="Non-deductible"
                    validateStatus={errors?.latenessNonDeduct ? 'error' : ''}
                    help={errors?.latenessNonDeduct}
                  >
                    {readOnly ? (
                      <>{formatHourMinute(formData.latenessNonDeduct, 'minute')}</>
                    ) : (
                      <Space>
                        <Input.Number
                          value={formData.latenessNonDeduct}
                          readOnly={readOnly}
                          onChange={latenessNonDeduct => {
                            const actual = dailyRecord?.latenessActual || 0
                            const nonDeduct = (latenessNonDeduct as number) || 0
                            handleFormDataChange({
                              latenessNonDeduct,
                              latenessDeduct: actual > nonDeduct ? actual - nonDeduct : 0
                            })
                          }}
                        />
                        <span>m</span>
                      </Space>
                    )}
                  </Form.Item>
                </Col>
                <Col flex="126px">
                  <Form.Item
                    label="Deductible"
                    validateStatus={errors?.latenessDeduct ? 'error' : ''}
                    help={errors?.latenessDeduct}
                  >
                    {readOnly ? (
                      <>{formatHourMinute(formData.latenessDeduct, 'minute')}</>
                    ) : (
                      <Space>
                        <Input.Number
                          value={formData.latenessDeduct}
                          readOnly={readOnly}
                          onChange={latenessDeduct => {
                            const actual = dailyRecord?.latenessActual || 0
                            const unpaid = (latenessDeduct as number) || 0
                            handleFormDataChange({
                              latenessDeduct,
                              latenessNonDeduct: actual > unpaid ? actual - unpaid : 0
                            })
                          }}
                        />
                        <span>m</span>
                      </Space>
                    )}
                  </Form.Item>
                </Col>
                <Col flex="1">
                  <Form.Item
                    label="Leave"
                    validateStatus={errors?.latenessLeaveTypeId ? 'error' : ''}
                    help={errors?.latenessLeaveTypeId}
                  >
                    {readOnly ? (
                      <>{leaveTypesDict[formData.latenessLeaveTypeId || '']?.name || '-'}</>
                    ) : (
                      <Select
                        showSearch
                        optionFilterProp="title"
                        value={formData.latenessLeaveTypeId}
                        readOnly={readOnly}
                        onChange={latenessLeaveTypeId => handleFormDataChange({ latenessLeaveTypeId })}
                      >
                        {leaveTypes.map(lt => (
                          <Select.Option key={lt.id} value={lt.id} title={lt.name}>
                            {lt.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <div className="section-title">Undertime</div>
              <Row gutter={15}>
                <Col flex="126px">
                  <Form.Item label="Actual">{formatHourMinute(dailyRecord?.undertimeActual, 'minute')}</Form.Item>
                </Col>
                <Col flex="126px">
                  <Form.Item
                    label="Non-deductible"
                    validateStatus={errors?.undertimeNonDeduct ? 'error' : ''}
                    help={errors?.undertimeNonDeduct}
                  >
                    {readOnly ? (
                      <>{formatHourMinute(formData.undertimeNonDeduct, 'minute')}</>
                    ) : (
                      <Space>
                        <Input.Number
                          value={formData.undertimeNonDeduct}
                          readOnly={readOnly}
                          onChange={undertimeNonDeduct => {
                            const actual = dailyRecord?.undertimeActual || 0
                            const nonDeduct = (undertimeNonDeduct as number) || 0
                            handleFormDataChange({
                              undertimeNonDeduct,
                              undertimeDeduct: actual > nonDeduct ? actual - nonDeduct : 0
                            })
                          }}
                        />
                        <span>m</span>
                      </Space>
                    )}
                  </Form.Item>
                </Col>
                <Col flex="126px">
                  <Form.Item
                    label="Deductible"
                    validateStatus={errors?.undertimeDeduct ? 'error' : ''}
                    help={errors?.undertimeDeduct}
                  >
                    {readOnly ? (
                      <>{formatHourMinute(formData.undertimeDeduct, 'minute')}</>
                    ) : (
                      <Space>
                        <Input.Number
                          value={formData.undertimeDeduct}
                          readOnly={readOnly}
                          onChange={undertimeDeduct => {
                            const actual = dailyRecord?.undertimeActual || 0
                            const deduct = (undertimeDeduct as number) || 0
                            handleFormDataChange({
                              undertimeDeduct,
                              undertimeNonDeduct: actual > deduct ? actual - deduct : 0
                            })
                          }}
                        />
                        <span>m</span>
                      </Space>
                    )}
                  </Form.Item>
                </Col>
                <Col flex="1">
                  <Form.Item
                    label="Leave"
                    validateStatus={errors?.undertimeLeaveTypeId ? 'error' : ''}
                    help={errors?.undertimeLeaveTypeId}
                  >
                    {readOnly ? (
                      <>{leaveTypesDict[formData.undertimeLeaveTypeId || '']?.name || '-'}</>
                    ) : (
                      <Select
                        showSearch
                        optionFilterProp="title"
                        value={formData.undertimeLeaveTypeId}
                        readOnly={readOnly}
                        onChange={undertimeLeaveTypeId => handleFormDataChange({ undertimeLeaveTypeId })}
                      >
                        {leaveTypes.map(lt => (
                          <Select.Option key={lt.id} value={lt.id} title={lt.name}>
                            {lt.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <div className="section-title">Overtime</div>
              <Row gutter={15}>
                <Col flex="126px">
                  <Form.Item label="Before">{formatHourMinute(dailyRecord?.otActualBefore)}</Form.Item>
                </Col>
                <Col flex="126px">
                  <Form.Item label="Within">{formatHourMinute(dailyRecord?.otActualWithin)}</Form.Item>
                </Col>
                <Col flex="126px">
                  <Form.Item label="After">{formatHourMinute(dailyRecord?.otActualAfter)}</Form.Item>
                </Col>
              </Row>
            </Card>
          </div>
          <div className="daily-record__body-right">
            <Card onKeyDown={handleKeyDown} onDoubleClick={handleEdit}>
              <Row gutter={15}>
                <Col flex="1">
                  {OT_LIST.map(
                    ot =>
                      otConfigs[ot]?.isShown && (
                        <Form.Item
                          key={ot}
                          label={otConfigs[ot]?.name}
                          validateStatus={errors && errors[ot] ? 'error' : ''}
                          help={errors && errors[ot]}
                        >
                          {readOnly ? (
                            <>{formData[ot as keyof FormData]}</>
                          ) : (
                            <Input.Number
                              value={formData[ot as keyof FormData] as number}
                              readOnly={readOnly}
                              onChange={value => handleFormDataChange({ [ot]: value })}
                            />
                          )}
                        </Form.Item>
                      )
                  )}
                </Col>
                <Col flex="1">
                  {TE_LIST.map(
                    te =>
                      teConfigs[te]?.isShownInDaily && (
                        <Form.Item
                          key={te}
                          label={teConfigs[te]?.name}
                          validateStatus={errors && errors[te] ? 'error' : ''}
                          help={errors && errors[te]}
                        >
                          {readOnly ? (
                            <>{formData[te as keyof FormData]}</>
                          ) : (
                            <Input.Number
                              value={formData[te as keyof FormData] as number}
                              readOnly={readOnly}
                              onChange={value => handleFormDataChange({ [te]: value })}
                            />
                          )}
                        </Form.Item>
                      )
                  )}
                </Col>
              </Row>
            </Card>
            {/* <DailyRecordLabels dailyRecordId={dailyRecord?.id} /> */}
          </div>
        </Form>
      </div>
    </div>
  )
}

const mapStateToDto = (state: DailyRecordState): IUpdateDailyRecord => ({
  id: state.id,
  employeeId: state.employeeId,
  clockDate: state.clockDate,
  timeInAdj: state.timeInAdj,
  timeOutAdj: state.timeOutAdj,
  workStatusType: state.workStatusType,
  shiftId: state.shiftId,
  breakId: state.breakId,
  locationId: state.locationId,
  latenessLeaveTypeId: state.latenessLeaveTypeId,
  latenessNonDeduct: state.latenessNonDeduct,
  latenessDeduct: state.latenessDeduct,
  undertimeLeaveTypeId: state.undertimeLeaveTypeId,
  undertimeNonDeduct: state.undertimeNonDeduct,
  undertimeDeduct: state.undertimeDeduct,
  ot01: state.ot01,
  ot02: state.ot02,
  ot03: state.ot03,
  ot04: state.ot04,
  ot05: state.ot05,
  ot06: state.ot06,
  ot07: state.ot07,
  ot08: state.ot08,
  ot09: state.ot09,
  ot10: state.ot10,
  te01: state.te01,
  te02: state.te02,
  te03: state.te03,
  te04: state.te04,
  te05: state.te05,
  te06: state.te06,
  te07: state.te07,
  te08: state.te08,
  te09: state.te09,
  te10: state.te10,
  notes: state.notes
})

const mapFormToDto = (dailyRecord: DailyRecordState, form: FormData): IUpdateDailyRecord => {
  let timeInAdj: string | null = form.startTime
    ? moment(dailyRecord.clockDate)
        .add(form.startDay, 'day')
        .add(moment.duration(form.startTime))
        .format('YYYY-MM-DD HH:mm:ss')
    : null

  if (timeInAdj === moment(dailyRecord.timeIn).format('YYYY-MM-DD HH:mm:ss')) {
    timeInAdj = null
  }

  let timeOutAdj: string | null = form.endTime
    ? moment(dailyRecord.clockDate)
        .add(form.endDay, 'day')
        .add(moment.duration(form.endTime))
        .format('YYYY-MM-DD HH:mm:ss')
    : null

  if (timeOutAdj === moment(dailyRecord.timeOut).format('YYYY-MM-DD HH:mm:ss')) {
    timeOutAdj = null
  }

  return {
    id: form.id,
    employeeId: dailyRecord.employeeId,
    clockDate: dailyRecord.clockDate,
    timeInAdj,
    timeOutAdj,
    workStatusType: form.workStatusType,
    shiftId: form.shiftId,
    breakId: form.breakId,
    locationId: form.locationId,
    latenessLeaveTypeId: form.latenessLeaveTypeId,
    latenessNonDeduct: form.latenessNonDeduct,
    latenessDeduct: form.latenessDeduct,
    undertimeLeaveTypeId: form.undertimeLeaveTypeId,
    undertimeNonDeduct: form.undertimeNonDeduct,
    undertimeDeduct: form.undertimeDeduct,
    ot01: form.ot01,
    ot02: form.ot02,
    ot03: form.ot03,
    ot04: form.ot04,
    ot05: form.ot05,
    ot06: form.ot06,
    ot07: form.ot07,
    ot08: form.ot08,
    ot09: form.ot09,
    ot10: form.ot10,
    te01: form.te01,
    te02: form.te02,
    te03: form.te03,
    te04: form.te04,
    te05: form.te05,
    te06: form.te06,
    te07: form.te07,
    te08: form.te08,
    te09: form.te09,
    te10: form.te10,
    notes: form.notes
  }
}
