import { showSuccess, showError } from '~/utils'
import { ThunkResult } from '~/types/store'
import { refetchDailyRecordsView, setDailyLabelsRefreshing } from '../reducers'
import { apiRefreshLabels } from '../api/daily-label.api'

export const refreshDailyLabel = (
  startDate: string,
  endDate: string,
  employeeIds: string[] = []
): ThunkResult<void> => {
  return async dispatch => {
    if (!startDate || !endDate) return

    try {
      dispatch(setDailyLabelsRefreshing(true))
      const { status, errors, message, errorData } = await apiRefreshLabels(startDate, endDate, employeeIds)
      if (status) {
        showSuccess('Success')
        dispatch(refetchDailyRecordsView())
      } else {
        if (Object.keys(errors).length === 0) {
          showError(message, errorData)
        }
      }
    } finally {
      dispatch(setDailyLabelsRefreshing(false))
    }
  }
}
