import { showError, showSuccess } from '~/utils'
import { ActionResult, ThunkResult } from '~/types/store'
import { apiVerify2fa } from '../api/two-factor.api'
import { setLogin } from '../reducers'

export const verify2fa = (code: string): ThunkResult<void> => {
  return async (dispatch, getState): Promise<ActionResult> => {
    const { status, result, errors, message, errorData } = await apiVerify2fa(code)
    if (status) {
      const myJwt = getState().iam.myJwt
      const myLogin = getState().iam.logins.entities[myJwt?.loginId || '']

      if (myLogin) {
        dispatch(setLogin({ ...myLogin, isTwoFactorEnabled: true }))
      }
      showSuccess('2FA enabled')
    } else {
      if (Object.keys(errors).length === 0) {
        showError(message, errorData)
      }
    }
    return { result, errors }
  }
}
