import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { InputRef } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import { QRCodeSVG } from 'qrcode.react'
import confirm from 'antd/lib/modal/confirm'
import { LoadingOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal, Space, Spin } from '~/core-components'
import { dispatch } from '~/stores/store'
import { disable2fa, enable2fa, verify2fa } from '../../../actions'
import { useMyLogin } from '../../../hooks'
import './TwoFactorAuth.less'

interface TwoFactorAuthProps {}

interface ITwoFactorSetup {
  sharedKey: string
  qrCodeUri: string
}

export const TwoFactorAuth: FC<TwoFactorAuthProps> = () => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [verifying, setVerifying] = useState(false)
  const [twoFactor, setTwoFactor] = useState<ITwoFactorSetup>()

  const [otp, setOtp] = useState(['', '', '', '', '', ''])
  const inputRefs = useRef<(InputRef | null)[]>([])

  const [myLogin] = useMyLogin()
  const formId = useMemo(() => `form-two-factor-auth-modal-${uuidv4()}`, [])

  const enableTwoFactor = useCallback(async () => {
    try {
      setLoading(true)
      const res = await dispatch(enable2fa())
      setTwoFactor(res.result)
      inputRefs.current[0]?.focus()
    } finally {
      setLoading(false)
    }
  }, [inputRefs])

  const disableTwoFactor = useCallback(async () => {
    try {
      setLoading(true)
      await dispatch(disable2fa())
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (open) {
      setOtp(['', '', '', '', '', ''])
      enableTwoFactor()
    }
  }, [open, enableTwoFactor])

  const verifyTwoFactor = useCallback(async () => {
    try {
      setVerifying(true)
      const result = await dispatch(verify2fa(otp.join('')))

      if (result?.errors) {
        setOtp(['', '', '', '', '', ''])
        inputRefs.current[0]?.focus()
      }

      if (!result?.errors) {
        setOpen(false)
      }
    } finally {
      setVerifying(false)
    }
  }, [otp])

  const handleOtpChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
      const newOtp = [...otp]
      newOtp[index] = e.target.value
      setOtp(newOtp)

      // Focus the next input if the current one is filled
      if (e.target.value && index < 5) {
        inputRefs.current[index + 1]?.focus()
      }
    },
    [otp, inputRefs]
  )

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
      if (e.key === 'Backspace' && !otp[index] && index > 0) {
        inputRefs.current[index - 1]?.focus() // Focus the previous input box
      }
    },
    [otp]
  )

  const handleSetup = useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleDisable = useCallback(() => {
    confirm({
      title: 'Disable 2FA',
      content: 'Do you want to disable 2FA?',
      onOk: () => {
        disableTwoFactor()
      },
      okText: 'Disable',
      okType: 'danger'
    })
  }, [disableTwoFactor])

  return (
    <div className="two-factor-auth">
      {myLogin.isTwoFactorEnabled ? (
        <Button onClick={handleDisable} loading={loading}>
          Disable 2FA
        </Button>
      ) : (
        <Button type="primary" onClick={handleSetup}>
          Setup 2FA
        </Button>
      )}
      <Modal
        className="two-factor-auth__modal"
        open={open}
        title="Scan QR code"
        onCancel={handleClose}
        width={450}
        formId={formId}
        okText=""
      >
        <Space size="middle" direction="vertical" align="center">
          <div>
            You will need a Microsoft Authenticator to
            <br />
            complete this process.
          </div>
          <div>
            {loading ? (
              <Spin size="small" indicator={<LoadingOutlined spin />} />
            ) : (
              twoFactor?.qrCodeUri && <QRCodeSVG value={twoFactor.qrCodeUri} size={256} />
            )}
          </div>
        </Space>
        <div className="two-factor-auth__separator" />
        <Form id={formId} onFinish={verifyTwoFactor}>
          <Space size="middle" direction="vertical" align="center">
            <div>
              Enter the 6-digit authentication code generated
              <br />
              by the Authenticator app.
            </div>
            <Space size="small">
              {otp.map((value, index) => (
                <Input
                  key={index}
                  value={value}
                  ref={el => (inputRefs.current[index] = el)} // Set input reference
                  onChange={e => handleOtpChange(e, index)}
                  onKeyDown={e => handleKeyDown(e, index)}
                  maxLength={1}
                  style={{
                    width: '40px',
                    height: '40px',
                    textAlign: 'center',
                    fontSize: '18px'
                  }}
                />
              ))}
              <Button
                type="primary"
                size="large"
                loading={verifying}
                disabled={otp.some(v => !v)}
                form={formId}
                htmlType="submit"
              >
                Verify
              </Button>
            </Space>
          </Space>
        </Form>
      </Modal>
    </div>
  )
}
