import { createSelector } from '@reduxjs/toolkit'
import memoize from 'lodash/memoize'
import moment from 'moment-timezone'
import { StoreState } from '~/types/store'
import { WorkStatusType } from '~/constants'

export const selectDayCode = createSelector(
  (state: StoreState) => state.attendance.dailyRecords,
  dailyRecordsState =>
    memoize((id: string | undefined): string => {
      const dailyRecord = dailyRecordsState.entities[id || '']
      if (!dailyRecord) return ''

      return getDayCode(dailyRecord.clockDate, dailyRecord.workStatusType, dailyRecord.isHoliday, dailyRecord.shiftId)
    })
)

export const getDayCode = (date: string, workStatusType: string, isHoliday?: boolean, shiftId?: string) => {
  if (!shiftId && isHoliday) {
    return 'hol'
  } else if (!shiftId && workStatusType === WorkStatusType.OffDay) {
    return 'off'
  } else if (!shiftId && workStatusType === WorkStatusType.RestDay) {
    return 'rest'
  } else {
    return moment(date).format('ddd').toLowerCase()
  }
}
